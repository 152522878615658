import without from '../lib/array/without';
import { endEditingOnNote } from '../reducers/note-tags';
import {
  getNoteTagAtId,
  getNoteTagsForNoteId,
} from '../selectors/note-tags';

export default function(store, action) {
  const { dispatch, getState } = store;
  const noteTag = getNoteTagAtId(getState(), action.id);
  const noteId = noteTag.note_id;
  const noteTags = getNoteTagsForNoteId(getState(), noteId);
  const nextNoteTags = without(noteTags, noteTag);

  if (nextNoteTags.length === 0) {
    dispatch(endEditingOnNote(noteId, nextNoteTags));
  }
}
