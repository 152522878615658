import uniq from '../lib/array/uniq';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeAllEntities,
  mergeEntityValues,
  setUniqueIds,
  toggleId,
} from '../lib/redux-tools';

export const ACTIVATE_ATTRIBUTION = 'ACTIVATE_ATTRIBUTION';
export const ACTIVATE_ATTRIBUTIONS = 'ACTIVATE_ATTRIBUTIONS';
const DEACTIVATE_ATTRIBUTION = 'DEACTIVATE_ATTRIBUTION';
const DELETE_ATTRIBUTION = 'DELETE_ATTRIBUTION';
export const SET_ATTRIBUTION = 'SET_ATTRIBUTION';
const SET_ATTRIBUTION_ROLES = 'SET_ATTRIBUTION_ROLES';
export const SET_ATTRIBUTIONS = 'SET_ATTRIBUTIONS';

export const activate = (idOrIds) =>
  Array.isArray(idOrIds)
    ? { type: ACTIVATE_ATTRIBUTIONS, ids: idOrIds }
    : { type: ACTIVATE_ATTRIBUTION, id: idOrIds };

export const setAll = (attributions) => ({
  type: SET_ATTRIBUTIONS,
  attributions,
});

export const setRoles = (roles) => ({
  type: SET_ATTRIBUTION_ROLES,
  roles,
});

export const set = (attribution, isNew = false) => ({
  type: SET_ATTRIBUTION,
  id: attribution.id,
  attribution,
  isNew,
});

const additionalIdFields = [
  'activeIds',
  'newIds',
];

const initialState = getInitialState({
  roles: [],
}, additionalIdFields);

export default create({
  [SET_ATTRIBUTION_ROLES]: (state, { roles }) => ({
    ...state,
    roles
  }),

  [SET_ATTRIBUTIONS]: (state, { attributions }) => ({
    ...state,
    entities: mergeAllEntities(state, attributions),
    ids: setUniqueIds(state, attributions),
  }),

  [SET_ATTRIBUTION]: (state, { attribution, isNew }) => ({
    ...state,
    entities: mergeEntityValues(state, attribution),
    ids: uniq([ ...state.ids, attribution.id ]),
    newIds: isNew
      ? uniq([ ...state.newIds, attribution.id ])
      : state.newIds
  }),

  [ACTIVATE_ATTRIBUTION]: (state, { id }) => toggleId(state, id, 'activeIds', true),
  [ACTIVATE_ATTRIBUTIONS]: (state, { ids }) => toggleId(state, ids, 'activeIds', true),
  [DEACTIVATE_ATTRIBUTION]: (state, { id }) => toggleId(state, id, 'activeIds', false),
  [DELETE_ATTRIBUTION]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
}, initialState);
