import React, { ReactNode } from 'react';

import setClasses from '../lib/dom/set-classes';

interface Props {
  children: ReactNode;
  size?: 'small' | 'large';
}

export const ItemActions = ({
  children,
  size = 'small',
}: Props) => {
  return (
    <div className={setClasses('item-actions', {
      'item-actions-small': size === 'small',
      'item-actions-large': size === 'large',
    })}>
      {children}
    </div>
  );
};

export default ItemActions;
