import { normalizeEntities } from '../lib/redux-tools';
import without from '../lib/object/without';
import mapIds from '../lib/record/map-ids';

import getAttributionsFromSource from '../adapters/attribution/get-from-source';

import {
  activate as activateAttributions,
  setAll as setAttributions,
} from '../reducers/attributions';
import { setAll as setPeople } from '../reducers/people';
import {
  activate as activateSourceTags,
  setAll as setSourceTags,
} from '../reducers/source-tags';
import { update as updateTag } from '../reducers/tags';

export const handleSetSource = (source) => dispatch => {
  if (source.medium.roles) {
    const _attributions = getAttributionsFromSource(source);

    if (_attributions) {
      const attributions = _attributions.map(({ attribution }) => attribution);
      const people = _attributions.map(({ person }) => person);

      if (attributions) {
        dispatch(setAttributions(attributions));
        dispatch(activateAttributions(mapIds(attributions)));
      }

      if (people) {
        dispatch(setPeople(people));
      }
    }
  }

  if (source.tags?.length) {
    const tagsToUpdate = source.tags.map(sourceTag => sourceTag.tag);
    const sourceTagsToSet = source.tags.map(sourceTag => without(sourceTag, 'tag'));

    dispatch(setSourceTags(normalizeEntities(sourceTagsToSet)));
    dispatch(activateSourceTags(sourceTagsToSet.map(sourceTag => sourceTag.id)));

    tagsToUpdate.forEach(tag => {
      dispatch(updateTag(tag));
    });
  }
};

export default function(store, action) {
  const { dispatch } = store;
  const { sources, source } = action;

  if (sources?.length) {
    sources.forEach(source => {
      dispatch(handleSetSource(source));
    });
  }

  if (source) {
    dispatch(handleSetSource(source));
  }
}
