import {
  ACTIVATE_MESSAGE,
  ADD_MESSAGE_TO_ACTIVATE_QUEUE,
  ADD_MESSAGE_TO_REMOVE_QUEUE,
  DEACTIVATE_MESSAGE,
} from '../reducers/messages';
import {
  LOOKUP_STEP_BACK,
} from '../reducers/lookup';
import {
  DELETE_NOTE_COMMENT_TASK,
  SET_NOTE_COMMENT_TASK,
} from '../reducers/note-comment-tasks';
import { SET_NOTE_RESULT } from '../reducers/notes';
import { DELETE_NOTE_TAG } from '../reducers/note-tags';
import {
  SET_PERSON,
  UPDATE_PERSON,
} from '../reducers/people';
import {
  SET_SOURCE,
  SET_SOURCE_CHILD_WAS_SAVED,
  SET_SOURCE_MEDIUM_VALUES,
  SET_SOURCES,
} from '../reducers/sources';
import { DELETE_SOURCE_TAG } from '../reducers/source-tags';
import {
  SET_TASK,
} from '../reducers/tasks';
import { SET_MODAL_IS_ACTIVE } from '../reducers/ui';

import handleActivateMessage from './handle-activate-message';
import handleAddMessageToActivateQueue from './handle-add-message-to-activate-queue';
import handleAddMessageToRemoveQueue from './handle-add-message-to-remove-queue';
import handleDeactivateMessage from './handle-deactivate-message';
import handleDeleteNoteTag from './handle-delete-note-tag';
import handleDeleteNoteCommentTask from './handle-delete-note-comment-task';
import handleDeleteSourceTag from './handle-delete-source-tag';
import handleLookupStepBack from './handle-lookup-step-back';
import handleSetModalIsActive from './handle-set-modal-is-active';
import handleSetNote from './handle-set-note';
import handleSetNoteCommentTask from './handle-set-note-comment-task';
import handleSetPerson, { handleUpdatePerson } from './handle-set-person';
import handleSetSourceChildWasSaved from './handle-set-source-child-was-saved';
import handleSetSourceMediumValues from './handle-set-source-medium-values';
import handleSetSources from './handle-set-sources';
import handleSetTask from './handle-set-task';

const handlers = store => next => action => {
  const types = {
    [ACTIVATE_MESSAGE]: handleActivateMessage,
    [ADD_MESSAGE_TO_ACTIVATE_QUEUE]: handleAddMessageToActivateQueue,
    [ADD_MESSAGE_TO_REMOVE_QUEUE]: handleAddMessageToRemoveQueue,
    [DEACTIVATE_MESSAGE]: handleDeactivateMessage,
    [DELETE_NOTE_COMMENT_TASK]: handleDeleteNoteCommentTask,
    [DELETE_NOTE_TAG]: handleDeleteNoteTag,
    [DELETE_SOURCE_TAG]: handleDeleteSourceTag,
    [LOOKUP_STEP_BACK]: handleLookupStepBack,
    [SET_MODAL_IS_ACTIVE]: handleSetModalIsActive,
    [SET_NOTE_COMMENT_TASK]: handleSetNoteCommentTask,
    [SET_NOTE_RESULT]: handleSetNote,
    [SET_PERSON]: handleSetPerson,
    [SET_SOURCE]: handleSetSources,
    [SET_SOURCE_CHILD_WAS_SAVED]: handleSetSourceChildWasSaved,
    [SET_SOURCE_MEDIUM_VALUES]: handleSetSourceMediumValues,
    [SET_SOURCES]: handleSetSources,
    [SET_TASK]: handleSetTask,
    [UPDATE_PERSON]: handleUpdatePerson,
  }

  if (action.type in types) {
    types[action.type](store, action);
  }

  return next(action);
};

export default handlers;
