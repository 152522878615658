import { SECTION_NOTE_COMMENT_TASKS } from '../constants.js';

import uniq from '../lib/array/uniq';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeAllEntities,
  mergeEntityValues,
  setUniqueIds,
  toggleId,
  updateItem,
} from '../lib/redux-tools';
import { deletePost, get, post, put } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes } from '../lib/route';

import { setWarning } from '../actions/messages';

import adapt from '../adapters/note-comment-task/adapt';
import buildNew from '../adapters/note-comment-task/build-new';
import toPost from '../adapters/note-comment-task/to-post';
import toPut from '../adapters/note-comment-task/to-put';

import buildNewTask from '../adapters/task/build-new';

import {
  SET_IS_ADDING_TASK_TO_NOTE_COMMENT,
  SET_IS_EDITING_TASK_ON_NOTE_COMMENT,
} from './comments';
import {
  INIT_NOTES,
} from './notes';
import {
  beginAdding as beginAddingTask,
  beginEditing as beginEditingTask,
  cancelAdd as cancelAddTask,
  cancelEdit as cancelEditTask,
  remove as removeTask,
  saveExisting as saveExistingTask,
  saveNew as saveNewTask,
  set as setTask,
} from './tasks';

import { getIsNewId, getValues } from '../selectors/note-comment-tasks';

const ACTIVATE_NOTE_COMMENT_TASK = 'ACTIVATE_NOTE_COMMENT_TASK';
const ACTIVATE_NOTE_COMMENT_TASKS = 'ACTIVATE_NOTE_COMMENT_TASKS';
const DEACTIVATE_NOTE_COMMENT_TASK = 'DEACTIVATE_NOTE_COMMENT_TASK';
export const DELETE_NOTE_COMMENT_TASK = 'DELETE_NOTE_COMMENT_TASK';
export const SET_NOTE_COMMENT_TASK = 'SET_NOTE_COMMENT_TASK';
const SET_NOTE_COMMENT_TASKS = 'SET_NOTE_COMMENT_TASKS';
const UPDATE_NOTE_COMMENT_TASK = 'UPDATE_NOTE_COMMENT_TASK';

const routes = getRoutes(SECTION_NOTE_COMMENT_TASKS);

export const activate = (idOrIds) =>
  Array.isArray(idOrIds)
    ? { type: ACTIVATE_NOTE_COMMENT_TASKS, ids: idOrIds }
    : { type: ACTIVATE_NOTE_COMMENT_TASK, id: idOrIds };

export const beginAdding = (id) =>
  setIsAdding(id, true);

export const beginEditing = (id, taskId) => dispatch => {
  dispatch(setIsEditing(id, true));
  dispatch(beginEditingTask(taskId));
}

export const cancelAdd = (id, taskId) => dispatch => {
  dispatch(deactivate(id));
  dispatch(endAdding(id));

  setTimeout(() => {
    dispatch(deleteItem(id));
  }, 400);

  dispatch(cancelAddTask(taskId));
};

export const cancelEdit = (id, taskId) => dispatch => {
  dispatch(endEditing(id));
  dispatch(cancelEditTask(taskId)); 
};

export const createNew = noteCommentId => async dispatch => {
  const result = await get(routes.new());
  const { data: { note_comment_task } } = result;
  const task = note_comment_task.record.task;

  const values = buildNew({
    ...note_comment_task.record,
    note_comment_id: noteCommentId,
  });

  Promise.resolve(
    dispatch(setTask(buildNewTask(task), true)),
    dispatch(set(values, true)),
  ).then(() => {
    dispatch(beginAddingTask(values.task_id));
    dispatch(beginAdding(values.id));
  });
};

export const deactivate = (id) => ({
  type: DEACTIVATE_NOTE_COMMENT_TASK, id,
});

export const deleteItem = (id) => ({
  type: DELETE_NOTE_COMMENT_TASK, id,
});

export const endAdding = (id) => setIsAdding(id, false);

export const endEditing = (id) => setIsEditing(id, false);

export const remove = (id, taskId) => async dispatch => {
  const handleError = () => {
    dispatch(setWarning());
  };

  const result = await deletePost(routes.remove(id)).catch(handleError);
  const taskResult = await dispatch(removeTask(taskId)).catch(handleError);

  if (result && taskResult) {
    dispatch(deactivate(id));

    setTimeout(async () => {
      dispatch(deleteItem(id));
    }, 400);
  }

  return result;
};

const saveExisting = (id, values, formValues) => async dispatch => {
  await dispatch(saveExistingTask(values.task_id, formValues));

  const result = await put(routes.detail(id), toPut(id, values)).catch(noop);
  const { data: { note_comment_task } } = result;

  dispatch(cancelEdit(id));
  dispatch(update(adapt(note_comment_task.record)));

  return result;
};

const saveNew = (_id, values, formValues) => async dispatch => {
  const taskResult = await dispatch(saveNewTask(values.task_id, formValues));
  const { data: { task } } = taskResult;

  const result = await post(routes.new(), toPost(_id, {
    note_comment_id: values.note_comment_id,
    task_id: task.record.id,
  })).catch(noop);
  const { data: { note_comment_task } } = result;

  dispatch(cancelAdd(_id));
  dispatch(set(adapt(note_comment_task.record)));

  return result;
};

export const save = (_id, formValues) => async (dispatch, getState) => {
  const state = getState();
  
  const isNew = getIsNewId(state, _id);
  const values = getValues(state, _id);

  const method = isNew ? saveNew : saveExisting;
  
  const result = await dispatch(method(_id, values, formValues));

  dispatch(dispatchActions(result));

  return result;
};

export const set = (noteCommentTask, isNew = false) => ({
  type: SET_NOTE_COMMENT_TASK, noteCommentTask, isNew,
});

export const setAll = (noteCommentTasks) => ({
  type: SET_NOTE_COMMENT_TASKS, noteCommentTasks,
});

export const setIsAdding = (id, isAdding) => ({
  type: SET_IS_ADDING_TASK_TO_NOTE_COMMENT, id, isAdding,
});

export const setIsEditing = (id, isEditing) => ({
  type: SET_IS_EDITING_TASK_ON_NOTE_COMMENT, id, isEditing,
});

export const update = (values) => ({
  type: UPDATE_NOTE_COMMENT_TASK, values,
});

const additionalIdFields = [
  'activeIds',
  'addingIds',
  'editingIds',
  'newIds',
];

const initialState = getInitialState({}, additionalIdFields);

export default create({
  [INIT_NOTES]: () => initialState,

  [SET_NOTE_COMMENT_TASKS]: (state, { noteCommentTasks }) => ({
    ...state,
    entities: mergeAllEntities(state, noteCommentTasks),
    ids: setUniqueIds(state, noteCommentTasks),
  }),

  [SET_NOTE_COMMENT_TASK]: (state, { noteCommentTask, isNew }) => ({
    ...state,
    entities: mergeEntityValues(state, noteCommentTask),
    ids: uniq([ ...state.ids, noteCommentTask.id ]),
    editingIds: isNew
      ? uniq([ ...state.editingIds, noteCommentTask.id ])
      : state.editingIds,
    newIds: isNew
      ? uniq([ ...state.newIds, noteCommentTask.id ])
      : state.newIds,
  }),

  [UPDATE_NOTE_COMMENT_TASK]: (state, { values }) => updateItem(state, values),

  [SET_IS_ADDING_TASK_TO_NOTE_COMMENT]: (state, { id, isAdding }) => toggleId(state, id, 'addingIds', isAdding),
  [SET_IS_EDITING_TASK_ON_NOTE_COMMENT]: (state, { id, isEditing }) => toggleId(state, id, 'editingIds', isEditing),
  [ACTIVATE_NOTE_COMMENT_TASK]: (state, { id }) => toggleId(state, id, 'activeIds', true),
  [ACTIVATE_NOTE_COMMENT_TASKS]: (state, { ids }) => toggleId(state, ids, 'activeIds', true),
  [DEACTIVATE_NOTE_COMMENT_TASK]: (state, { id }) => toggleId(state, id, ['activeIds', 'editingIds'], false),
  [DELETE_NOTE_COMMENT_TASK]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
}, initialState);
