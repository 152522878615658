import React from 'react';
import PropTypes from 'prop-types';

const Checkmark = ({ size = 18 }) => {
  return (
    <svg viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg' fillRule='evenodd' clipRule='evenodd' strokeLinejoin='round' strokeMiterlimit='2'>
      <path d='M15 1.5l-9 9-3-3-3 3 6 6 12-12-3-3z' fill='currentColor' fillRule='nonzero' />
    </svg>
  );
};

Checkmark.propTypes = {
  size: PropTypes.number
};

export default Checkmark;
