import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';

import setClasses from '../lib/dom/set-classes';

import { Id, Message as MessageType } from '../types';

import {
  deactivate as deactivateMessage,
  removeFromRemoveQueue,
} from '../reducers/messages';

interface Props extends MessageType {
  cancelAutoRemove: (id: Id) => void;
  deactivateMessage: (id: Id) => void;
  isActive?: boolean;
}

const Message = ({
  cancelAutoRemove,
  deactivateMessage,
  grade,
  id,
  isActive = false,
  message,
}: Props) => {
  const handleClick = (e: MouseEvent<HTMLLIElement>) => {
    e.preventDefault();

    cancelAutoRemove(id);
  };
  const handleCloseClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();

    deactivateMessage(id);
  };

  return (
    <li
      className={setClasses('flash-message', `flash-message-${grade}`, {
        active: isActive,
        inactive: !isActive,
      })}
      onClick={handleClick}
    >
      <span className='flash-message-content' dangerouslySetInnerHTML={{ __html: message }} />
      <span className='flash-message-dismiss' onClick={handleCloseClick}>dismiss</span>
    </li>
  );
};

const mapDispatchToProps = {
  deactivateMessage,
  cancelAutoRemove: removeFromRemoveQueue,
};

export default connect(null, mapDispatchToProps)(Message);
