import 'whatwg-fetch';

export const isOk = response => response.ok;

const handleResponse = response =>
  response.json()
    .then(json =>
      response.ok ? json : Promise.reject(json)
    );

const defaultOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  credentials: 'same-origin'
};

export const get = (url, options = {}) =>
  fetch(url, { ...defaultOptions, ...options })
    .then(handleResponse);

export const getRemote = (url, options = {}) =>
  fetch(url, options || {})
    .then(handleResponse);

export const getRemoteStatus = (url, options = {}) =>
  fetch(url, options || {});

export const post = (url, data = {}) =>
  fetch(url, {
    ...defaultOptions,
    body: JSON.stringify(data),
    method: 'POST'
  })
    .then(handleResponse);

export const put = (url, data = {}) =>
  fetch(url, {
    ...defaultOptions,
    body: JSON.stringify(data),
    method: 'PUT'
  })
    .then(handleResponse);

export const deletePost = (url, data = {}) =>
  fetch(url, {
    ...defaultOptions,
    body: JSON.stringify(data),
    method: 'DELETE'
  })
    .then(handleResponse);
