import { normalizeEntities } from '../lib/redux-tools';

import {
  activate as activatePersonTags,
  setAll as setPersonTags,
} from '../reducers/person-tags';
import {
  set as setTag,
  update as updateTag,
} from '../reducers/tags';
import { getHasTag } from '../selectors/tags';

const setAll = personTags => (dispatch, getState) => {
  const state = getState();

  if (personTags?.length) {
    const tagsToUpdate = personTags.map(personTag => personTag.tag);

    dispatch(setPersonTags(normalizeEntities(personTags)));
    dispatch(activatePersonTags(personTags.map(personTag => personTag.id)));

    tagsToUpdate.filter(Boolean).forEach(tag => {
      if (getHasTag(state, tag.id)) {
        dispatch(updateTag(tag));
      } else {
        dispatch(setTag(tag));
      }
    });
  }
};

export const handleUpdatePerson = (store, action) => {
  const { dispatch } = store;
  const { values } = action;

  const personTags = [];

  if (values.tag) {
    personTags.push(values.tag);
  }

  dispatch(setAll(personTags));
};

export default function(store, action) {
  const { dispatch } = store;
  const { person } = action;

  const personTags = [];

  if (person.tag) {
    personTags.push(person.tag);
  }

  if (person.tags?.length) {
    personTags.concat(person.tags);
  }

  dispatch(setAll(personTags));
}
