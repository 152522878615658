import { cancelAdd } from '../reducers/sources';

const setHasModal = (isActive) => {
  if (isActive) {
    document.body.classList.add('has-modal');
  } else {
    document.body.classList.remove('has-modal');
  }
}

export default function(store, action) {
  const { dispatch, getState } = store;
  const state = getState();
  const { env, ui: { modal: { contents, kindOf } } } = state;
  const id = contents?.id;

  setHasModal(action.isActive);

  if (action.isActive === false) {
    if (kindOf === 'lookup') {
        dispatch(cancelAdd(id));
    } else if (env.section.isSources) {
      if (env.action.isEdit) {
        // todo
      } else if (env.action.isShow) {
        dispatch(cancelAdd(id));
      }
    }
  }
}
