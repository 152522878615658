import { createSelector } from 'reselect'

const getIndexesState = state => state.indexes;

const getIds = createSelector(getIndexesState, indexes => indexes.ids);
const getById = createSelector(getIndexesState, indexes => indexes.entities);
const getActiveIds = createSelector(getIndexesState, indexes => indexes.activeIds);
const getNewIds = createSelector(getIndexesState, indexes => indexes.newIds);
const getIsAdding = createSelector(getIndexesState, indexes => indexes.isAdding);

export const getPagination = createSelector(getIndexesState, indexes => indexes.pagination);

export const getActive = createSelector(
  [getById, getActiveIds], (entities, ids) => ids.map(id => entities[id])
);

const getNew = createSelector(
  [getById, getNewIds], (entities, ids) => ids.map(id => entities[id])
);

export const getHasActiveIndexedItems = createSelector(getActive, indexedItems => indexedItems.length > 0);

export const getIsActiveId = (state, id) => getActiveIds(state).includes(id);

export const getIndexedItemAtId = (state, id) => getById(state)[id] || {};
export const getHasIndexedItem = (state, id) => getIds(state).includes(id);
export const getNewIndexedItem = state => getNew(state)[0]; // bad
export const getHasNewIndexedItem = state => getIsAdding(state) && getNewIds(state).length > 0; // bad

export const getIsNewId = (state, id) => getNewIds(state).includes(id);
