import { SECTION_INDEXES } from '../constants.js';

import uniq from '../lib/array/uniq';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeAllEntities,
  mergeEntityValues,
  setIsAdding,
  setIsRequesting,
  setUniqueIds,
  toggleId,
  updateItem,
} from '../lib/redux-tools';

import { deletePost, post, put } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes, redirectTo } from '../lib/route';

import toPost from '../adapters/indexes/to-post';
import toPut from '../adapters/indexes/to-put';

import { getIsNewId } from '../selectors/indexes';

const ACTIVATE_INDEXED_ITEM = 'ACTIVATE_INDEXED_ITEM';
const ACTIVATE_INDEXED_ITEMS = 'ACTIVATE_INDEXED_ITEMS';
const BEGIN_INDEXED_ITEM_REQUEST = 'BEGIN_INDEXED_ITEM_REQUEST';
const DEACTIVATE_INDEXED_ITEM = 'DEACTIVATE_INDEXED_ITEM';
const DELETE_INDEXED_ITEM = 'DELETE_INDEXED_ITEM';
const END_INDEXED_ITEM_REQUEST = 'END_INDEXED_ITEM_REQUEST';
const SET_INDEXED_ITEM = 'SET_INDEXED_ITEM';
const SET_INDEXED_ITEMS = 'SET_INDEXED_ITEMS';
const SET_INDEXED_ITEMS_PAGINATION = 'SET_INDEXED_ITEMS_PAGINATION';
const SET_IS_ADDING_INDEXED_ITEM = 'SET_IS_ADDING_INDEXED_ITEM';
const UPDATE_INDEXED_ITEM = 'UPDATE_INDEXED_ITEM';

const routes = getRoutes(SECTION_INDEXES);

export const activate = (idOrIds) =>
  Array.isArray(idOrIds)
    ? { type: ACTIVATE_INDEXED_ITEMS, ids: idOrIds, }
    : { type: ACTIVATE_INDEXED_ITEM, id: idOrIds, };

export const deleteItem = (id) => ({
  type: DELETE_INDEXED_ITEM, id,
});

export const removeExisting = (kindOf, id) => dispatch =>
  deletePost(routes.remove(id, kindOf))
    .then(result => {
      dispatch(deleteItem(id));
      setTimeout(() => {
        redirectTo(routes.kindOf(kindOf));
      }, 400);

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const beginRequest = () => ({
  type: BEGIN_INDEXED_ITEM_REQUEST,
});

export const endRequest = () => ({
  type: END_INDEXED_ITEM_REQUEST,
})

const saveExisting = (kindOf, id, values) => dispatch =>
  put(routes.edit(id, kindOf), toPut(id, values))
    .then(result => {
      const { data } = result;
      const { item } = data;

      dispatch(update(item.record));

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

const saveNew = (kindOf, id, values) => dispatch =>
  post(routes.new(kindOf), toPost(id, values))
    .then(result => {
      const { data } = result;
      const { item } = data;

      setTimeout(() => {
        redirectTo(routes.detail(item.record.id, kindOf));
      }, 400);

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const save = (kindOf, id, values) => (dispatch, getState) => {
  const isNew = getIsNewId(getState(), id);
  const method = isNew ? saveNew : saveExisting;

  return dispatch(method(kindOf, id, values));
};

export const set = (indexedItem, isNew = false) => ({
  type: SET_INDEXED_ITEM, indexedItem, isNew,
});

export const setAll = (items) => ({
  type: SET_INDEXED_ITEMS, items,
});

export const setIsAddingItem = (isAdding) => ({
  type: SET_IS_ADDING_INDEXED_ITEM, isAdding,
});

export const setPagination = (pagination) => ({
  type: SET_INDEXED_ITEMS_PAGINATION, pagination,
});

export const update = (values) => ({
  type: UPDATE_INDEXED_ITEM, values,
});

const additionalIdFields = [
  'activeIds',
  'editingIds',
  'newIds',
];

const initialState = getInitialState({
  isRequesting: false,
  isAdding: false,
  pagination: null,
}, additionalIdFields);

export default create({
  [BEGIN_INDEXED_ITEM_REQUEST]: state => setIsRequesting(state, true),
  [END_INDEXED_ITEM_REQUEST]: state => setIsRequesting(state, false),
  [SET_IS_ADDING_INDEXED_ITEM]: (state, { isAdding }) => setIsAdding(state, isAdding),

  [SET_INDEXED_ITEMS]: (state, { items }) => ({
    ...state,
    entities: mergeAllEntities(state, items),
    ids: setUniqueIds(state, items),
  }),

  [SET_INDEXED_ITEMS_PAGINATION]: (state, { pagination }) => ({
    ...state,
    pagination,
  }),

  [SET_INDEXED_ITEM]: (state, { indexedItem, isNew }) => ({
    ...state,
    entities: mergeEntityValues(state, indexedItem),
    ids: uniq([ ...state.ids, indexedItem.id ]),
    editingIds: isNew
      ? uniq([ ...state.editingIds, indexedItem.id ])
      : state.editingIds,
    newIds: isNew
      ? uniq([ ...state.newIds, indexedItem.id ])
      : state.newIds,
  }),

  [UPDATE_INDEXED_ITEM]: (state, { values }) => updateItem(state, values),
  [ACTIVATE_INDEXED_ITEM]: (state, { id }) => toggleId(state, id, 'activeIds', true),
  [ACTIVATE_INDEXED_ITEMS]: (state, { ids }) => toggleId(state, ids, 'activeIds', true),
  [DEACTIVATE_INDEXED_ITEM]: (state, { id }) => toggleId(state, id, 'activeIds', false),
  [DELETE_INDEXED_ITEM]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
}, initialState);
