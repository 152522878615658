import { create } from '../lib/redux-tools';

import {
  LOOKUP_CLEAR_FORM,
  LOOKUP_CLEAR_RESULTS,
  LOOKUP_SET_SEARCH_TERMS,
} from './lookup';

export const setSearchTerms = (terms) => ({
  type: LOOKUP_SET_SEARCH_TERMS, terms,
});

const initialState = {
  terms: {}
};

export default create({
  [LOOKUP_CLEAR_FORM]: () => initialState,
  [LOOKUP_CLEAR_RESULTS]: () => initialState,

  [LOOKUP_SET_SEARCH_TERMS]: (state, { terms }) => ({
    ...state,
    terms
  })
}, initialState);
