import {
  LOOKUP,
  NEW_SELECTION,
  SECTION_SOURCES,
} from '../constants.js';

import uniq from '../lib/array/uniq';
import without from '../lib/array/without';
import withoutProp from '../lib/object/without';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeAllEntities,
  mergeEntityValues,
  setIsRequesting,
  setUniqueIds,
  toggleId,
} from '../lib/redux-tools';
import { deletePost, get } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes, redirectTo } from '../lib/route';

import getNewMedium from '../adapters/medium/get-new';
import adapt from '../adapters/source/adapt';

import { SET_CITATION } from './citations';
import { clearForm } from './lookup';
import {
  SET_IS_ADDING_SOURCE,
  SET_MODAL_CONTENTS,
  SET_MODAL_IS_ACTIVE,
  SET_MODAL_KIND_OF,
  setIsAddingSource,
  setModalContents,
  setModalIsActive,
  setModalKindOf,
} from './ui';

const SET_PARTITIONINGS = 'SET_PARTITIONINGS';
const SET_SOURCE_KINDS_OF = 'SET_SOURCE_KINDS_OF';
export const ACTIVATE_SOURCE = 'ACTIVATE_SOURCE';
export const ACTIVATE_SOURCES = 'ACTIVATE_SOURCES';
export const ADD_CHILD_TO_SOURCE = 'ADD_CHILD_TO_SOURCE';
export const BEGIN_SOURCE_REQUEST = 'BEGIN_SOURCE_REQUEST';
export const DEACTIVATE_SOURCE = 'DEACTIVATE_SOURCE';
export const DELETE_SOURCE = 'DELETE_SOURCE';
export const END_SOURCE_REQUEST = 'END_SOURCE_REQUEST';
export const REMOVE_CHILD_FROM_SOURCE = 'REMOVE_CHILD_FROM_SOURCE';
export const SET_SOURCE = 'SET_SOURCE';
const SET_SOURCE_CHILD_GROUPS = 'SET_SOURCE_CHILD_GROUPS';
export const SET_SOURCE_CHILD_WAS_SAVED = 'SET_SOURCE_CHILD_WAS_SAVED';
export const SET_SOURCE_MEDIUM_FORMATS = 'SET_SOURCE_MEDIUM_FORMATS';
export const SET_SOURCE_MEDIUM_VALUES = 'SET_SOURCE_MEDIUM_VALUES';
export const SET_SOURCE_VALUES = 'SET_SOURCE_VALUES';
export const SET_SOURCES = 'SET_SOURCES';
const SET_SOURCES_PAGINATION = 'SET_SOURCES_PAGINATION';
export const SET_IS_ADDING_TAG_TO_SOURCE = 'SET_IS_ADDING_TAG_TO_SOURCE';
export const SET_IS_EDITING_TAGS_ON_SOURCE = 'SET_IS_EDITING_TAGS_ON_SOURCE';

const routes = getRoutes(SECTION_SOURCES);

export const activate = (idOrIds) =>
  Array.isArray(idOrIds)
    ? { type: ACTIVATE_SOURCES, ids: idOrIds }
    : { type: ACTIVATE_SOURCE, id: idOrIds };

export const addChildToSource = (source) => ({
  type: ADD_CHILD_TO_SOURCE, source,
});

export const beginRequest = () => ({
  type: BEGIN_SOURCE_REQUEST,
});

export const cancelAdd = id => (dispatch, getState) => {
  const source = getState().sources.entities[id];

  dispatch(deactivate(id));
  dispatch(setIsAddingSource(false));
  dispatch(clearForm());

  if (source?.parent?.id) {
    setTimeout(() => dispatch(removeChildFromSource(source)), 800);
  } else {
    setTimeout(() => dispatch(deleteItem(id)), 800);
  }
};

// todo: fix data
export const createNew = (medium = null, parent = null) => dispatch =>
  getNew(medium, parent)
    .then(data => {
      const values = { type: 'source', id: data.id };

      if (parent) {
        dispatch(addChildToSource(data));
        dispatch(setIsAddingSource(true)); // todo: setIsAddingSourceToParent
      } else {
        dispatch(set(data, true));
      }

      dispatch(setModalKindOf(parent ? NEW_SELECTION : LOOKUP));
      dispatch(setModalContents(values));
      dispatch(setModalIsActive(true));

      return data;
    });

export const deactivate = (id) => ({
  type: DEACTIVATE_SOURCE, id,
});

export const deleteItem = (id) => ({
  type: DELETE_SOURCE, id,
});

export const endRequest = () => ({
  type: END_SOURCE_REQUEST,
});

export const getChildrenForSource = (source) => dispatch => {
  dispatch(beginRequest());
  get(routes.children(source.id))
    .then(result => {
      const { data } = result;
      const { children, groups } = data;
      dispatch(setChildGroups(source.id, groups));
      dispatch(setAll(children.records));
      dispatch(endRequest());

      return result;
    });
};

export const getNew = (medium = null, parent = null) =>
  get(parent ? routes.new() + `?parent_id=${parent.id}&medium=${medium}` : routes.new())
    .then(result => {
      const { data } = result;
      const record = adapt(data.source.record);

      if (medium) {
        record.medium = getNewMedium({ ...record.medium, medium }) // todo: from the API
      }

      return record;
    });

// get-notes-for-source.js
// todo todo todo

export const processData = data => set(adapt(data.record));

export const removeChildFromSource = (source) => ({
  type: REMOVE_CHILD_FROM_SOURCE, source,
});

export const removeExisting = (id, parent_id = null) => dispatch =>
  deletePost(routes.remove(id))
    .then(result => {
      dispatch(deactivate(id));
      setTimeout(() => {
        dispatch(deleteItem(id));
      }, 400);
      setTimeout(() => {
        redirectTo(parent_id ? routes.detail(parent_id) : routes.base());
      }, 800);

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

// save.js
// save-child.js
// todo todo todo

export const set = (source, isNew = false) => ({
  type: SET_SOURCE, source, isNew,
});

export const setAll = (sources) => ({
  type: SET_SOURCES, sources,
});

const setChildGroups = (id, childGroups) => ({
  type: SET_SOURCE_CHILD_GROUPS, id, childGroups,
});

export const setChildWasSaved = (source) => ({
  type: SET_SOURCE_CHILD_WAS_SAVED, source,
});

export const setKindsOf = (kindsOf) => ({
  type: SET_SOURCE_KINDS_OF, kindsOf,
});

export const setMediumFormats = (formats) => ({
  type: SET_SOURCE_MEDIUM_FORMATS, formats,
});

export const setValues = (id, values) => ({
  type: SET_SOURCE_VALUES, id, values,
});

export const setMediumValues = (id, values) => ({
  type: SET_SOURCE_MEDIUM_VALUES, id, values,
});

export const setPagination = (pagination) => ({
  type: SET_SOURCES_PAGINATION, pagination,
});

export const setPartitionings = (partitionings) => ({
  type: SET_PARTITIONINGS, partitionings,
});

const additionalIdFields = [
  'activeIds',
  'newIds',
  'isAddingTagToIds',
  'isEditingTagsOnIds',
];

const initialState = getInitialState({
  citations: {},
  formats: {},
  isAdding: false,
  isRequesting: false,
  modal: {
    contents: null,
    isActive: false,
    kindOf: null,
  },
  kindsOf: [],
  pagination: null,
  partitionings: {},
}, additionalIdFields);

export default create({
  [BEGIN_SOURCE_REQUEST]: state => setIsRequesting(state, true),
  [END_SOURCE_REQUEST]: state => setIsRequesting(state, false),

  [SET_IS_ADDING_SOURCE]: (state, { isAddingSource }) => ({
    ...state,
    isAdding: isAddingSource,
  }),

  [SET_PARTITIONINGS]: (state, { partitionings }) => ({
    ...state,
    partitionings,
  }),

  [SET_SOURCES]: (state, { sources }) => ({
    ...state,
    entities: mergeAllEntities(state, sources),
    ids: setUniqueIds(state, sources),
  }),

  [SET_SOURCE]: (state, { source, isNew }) => ({
    ...state,
    entities: mergeEntityValues(state, source),
    ids: uniq([ ...state.ids, source.id ]),
    newIds: isNew
      ? uniq([ ...state.newIds, source.id ])
      : state.newIds,
  }),

  [SET_SOURCE_CHILD_GROUPS]: (state, { id, childGroups }) => ({
    ...state,
    entities: {
      ...state.entities,
      [id]: {
        ...state.entities[id],
        childGroups,
      },
    },
  }),

  [SET_SOURCE_KINDS_OF]: (state, { kindsOf }) => ({
    ...state,
    kindsOf,
  }),

  [SET_SOURCE_MEDIUM_FORMATS]: (state, { formats }) => ({
    ...state,
    formats,
  }),

  [SET_SOURCE_MEDIUM_VALUES]: (state, { id, values }) => ({
    ...state,
    entities: {
      ...state.entities,
      [id]: {
        ...state.entities[id],
        medium: {
          ...state.entities[id].medium,
          ...values
        }
      }
    },
  }),

  [SET_SOURCE_VALUES]: (state, { id, values }) => ({
    ...state,
    entities: {
      ...state.entities,
      [id]: {
        ...state.entities[id],
        ...values,
      }
    },
  }),

  [ADD_CHILD_TO_SOURCE]: (state, { source }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [source.id]: {
          ...state.entities[source.id],
          ...source
        },
        [source.parent.id]: {
          ...state.entities[source.parent.id],
          childGroups: state.entities[source.parent.id].childGroups.map(group => {
            return (group.label === source.medium.collection_label)
              ? { ...group, ids: [ ...group.ids, source.id ] }
              : group;
          }),
        }
      },
      ids: uniq([ ...state.ids, source.id ]),
      newIds: uniq([ ...state.newIds, source.id ]),
    };
  },

  [REMOVE_CHILD_FROM_SOURCE]: (state, { source }) => {
    const remainder = withoutProp(state.entities, source.id);

    return {
      ...state,
      entities: {
        ...remainder,
        [source.parent.id]: {
          ...state.entities[source.parent.id],
          childGroups: state.entities[source.parent.id].childGroups.map(group => {
            return (group.label === source.medium.collection_label)
              ? { ...group, ids: without(group.ids, source.id) }
              : group;
          }),
        }
      },
      ids: without(state.ids, source.id),
      newIds: without(state.newIds, source.id),
    };
  },

  [ACTIVATE_SOURCE]: (state, { id }) => toggleId(state, id, 'activeIds', true),
  [ACTIVATE_SOURCES]: (state, { ids }) => toggleId(state, ids, 'activeIds', true),
  [DEACTIVATE_SOURCE]: (state, { id }) => toggleId(state, id, 'activeIds', false),
  [DELETE_SOURCE]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
  [SET_IS_ADDING_TAG_TO_SOURCE]: (state, { id, isAddingTag }) => toggleId(state, id, 'isAddingTagToIds', isAddingTag),
  [SET_IS_EDITING_TAGS_ON_SOURCE]: (state, { id, isEditingTags }) => toggleId(state, id, 'isEditingTagsOnIds', isEditingTags),

  [SET_SOURCES_PAGINATION]: (state, { pagination }) => ({
    ...state,
    pagination,
  }),

  [SET_MODAL_CONTENTS]: (state, { contents }) => {
    if (contents === null) {
      return {
        ...state,
        modal: {
          ...initialState.modal,
        }
      };
    } else if (contents?.type === 'source') {
      return {
        ...state,
        modal: {
          ...state.modal,
          contents,
        },
      };
    } else {
      return { ...state };
    }
  },

  [SET_MODAL_IS_ACTIVE]: (state, { isActive }) => {
    if (state.modal.contents?.type === 'source') {
      return {
        ...state,
        modal: {
          ...state.modal,
          isActive,
        },
      };
    } else {
      return { ...state };
    }
  },

  [SET_MODAL_KIND_OF]: (state, { kindOf }) => ({
    ...state,
    modal: {
      ...state.modal,
      kindOf,
    }
  }),

  [SET_CITATION]: (state, { citation }) => {
    const { collection, id } = citation;

    if (collection === 'sources') {
      return {
        ...state,
        citations: {
          ...state.citations,
          [id]: citation,
        },
      }
    } else {
      return state;
    }
  },
}, initialState);
