import { combineReducers } from 'redux';

import env from './env';
import messages from './messages';
import ui from './ui';

const reducers = {
  env,
  messages,
  ui,
};

export default combineReducers(reducers);
