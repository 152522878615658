import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import { compose } from 'redux';

import { setAndActivate as setAndActivateMessage } from './reducers/messages';

import reducer from './reducers/login';
import getStore from './stores/index';
import { Message } from './types';

import App from './components/login/app';

type InitData = {
  messages: Message[];
}

declare global {
  interface Window {
    initData?: InitData;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const { messages = [] } = window.initData;

const store = getStore(reducer);
const { dispatch } = store;

const target = document.getElementById('content');

messages.forEach((message: Message) => dispatch(setAndActivateMessage(message)));

if (target) {
  const layoutRoot = createRoot(target);

  layoutRoot.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}
