import { createSelector } from 'reselect'

import toFormValues from '../adapters/note-tag/to-form-values';
import getStringsForNullValues from '../lib/object/get-strings-for-null-values';

import { getById as getNotesById } from './notes';

const getNoteTagsState = state => state.noteTags;

const getById = createSelector(getNoteTagsState, noteTags => noteTags.entities);
const getIds = createSelector(getNoteTagsState, noteTags => noteTags.ids);
export const getActiveIds = createSelector(getNoteTagsState, noteTags => noteTags.activeIds);
export const getEditingIds = createSelector(getNoteTagsState, noteTags => noteTags.editingIds);
const getNewIds = createSelector(getNoteTagsState, noteTags => noteTags.newIds);

const getNoteTags = createSelector(
  [getIds, getById], (ids, entities) => ids.map(id => entities[id])
);

const getNewNoteTags = createSelector(
  [getNewIds, getById], (ids, entities) => ids.map(id => entities[id])
);

export const getNoteTagsForNoteId = (state, noteId) =>
  getNoteTags(state).filter(noteTag => noteTag.note_id === noteId);

export const getNewNoteTagForNoteId = (state, noteId) =>
  getNewNoteTags(state).find(noteTag => noteTag.note_id === noteId);

export const getTagIsSetOnNote = (state, noteId, tagId) =>
  getNoteTagsForNoteId(state, noteId).map(noteTag => noteTag.tag_id).includes(tagId);

export const getIsNewId = (state, id) => getNewIds(state).includes(id);

export const getNoteTagAtId = (state, id) => getById(state)[id] || {};
export const getInitialState = (state, id) => toFormValues(getStringsForNullValues(getNoteTagAtId(state, id)));
export const getValues = (state, id) => toFormValues(getNoteTagAtId(state, id));

export const getNotesByTagId = createSelector(
  [getNoteTags, getNotesById], (noteTags, notesById) => {
    return noteTags.reduce((notesByTagId, noteTag) => {
      const { note_id, tag_id } = noteTag;

      if (!(tag_id in notesByTagId)) {
        notesByTagId[tag_id] = [];
      }

      notesByTagId[tag_id].push(notesById[note_id]);

      return notesByTagId;
    }, {});
  }
);
