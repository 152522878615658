import { createSelector } from 'reselect'

const getPeopleState = state => state.people;

const getIds = createSelector(getPeopleState, people => people.ids);
export const getById = createSelector(getPeopleState, people => people.entities);
export const getActiveIds = createSelector(getPeopleState, people => people.activeIds);

export const getIsRequesting = createSelector(getPeopleState, people => people.isRequesting);
export const getIsAddingTagToIds = createSelector(getPeopleState, people => people.isAddingTagToIds);
export const getIsEditingTagsOnIds = createSelector(getPeopleState, people => people.isEditingTagsOnIds);
export const getPagination = createSelector(getPeopleState, people => people.pagination);

export const getPeople = createSelector(
  [getIds, getById], (ids, entities) => ids.map(id => entities[id])
);

export const getActive = createSelector(
  [getById, getActiveIds], (entities, ids) => ids.map(id => entities[id])
);

export const getHasActivePeople = createSelector(getActive, people => people.length > 0);

export const getIsActiveId = (state, id) => getActiveIds(state).includes(id);

export const getHasPerson = (state, id) => getIds(state).includes(id);

export const getPeopleMatchingValue = (state, value = '', threshold = 3) => {
  if (value.length >= threshold) {
    return getPeople(state).filter(person => new RegExp(value, 'i').test(person.name));
  } else {
    return [];
  }
};

export const getPersonAtId = (state, id) => getById(state)[id] || {};
