import { createSelector } from 'reselect'

import toFormValues from '../adapters/note-comment-task/to-form-values';
import createdAsc from '../lib/sorting/created-asc';

const getNoteCommentTasksState = state => state.noteCommentTasks;

const getIds = createSelector(getNoteCommentTasksState, noteCommentTasks => noteCommentTasks.ids);
const getById = createSelector(getNoteCommentTasksState, noteCommentTasks => noteCommentTasks.entities);
const getActiveIds = createSelector(getNoteCommentTasksState, noteCommentTasks => noteCommentTasks.activeIds);
const getAddingIds = createSelector(getNoteCommentTasksState, noteCommentTasks => noteCommentTasks.addingIds);
const getEditingIds = createSelector(getNoteCommentTasksState, noteCommentTasks => noteCommentTasks.editingIds);
const getNewIds = createSelector(getNoteCommentTasksState, noteCommentTasks => noteCommentTasks.newIds);

export const getTasksByNoteCommentId = createSelector( //
  [getById, getIds], (entities, ids) =>
    ids.reduce((byNoteCommentId, id) => {
      const item = entities[id];
      const { note_comment_id } = item;

      if (!(note_comment_id in byNoteCommentId)) {
        byNoteCommentId[note_comment_id] = [];
      }

      byNoteCommentId[note_comment_id].push(item);
      byNoteCommentId[note_comment_id].sort(createdAsc);

      return byNoteCommentId;
    },
    {})
);

export const getIsActiveId = (state, id) => getActiveIds(state).includes(id); //
// export const getIsAddingId = (state, id) => getAddingIds(state).includes(id);
export const getIsEditingId = (state, id) => getEditingIds(state).includes(id); //
export const getIsNewId = (state, id) => getNewIds(state).includes(id); //

export const getIsAdding = (state) => getAddingIds(state).length > 0; //
export const getIsEditing = (state) => getAddingIds(state).length > 0;

export const getAtId = (state, id) => getById(state)[id] || {}; //
export const getValues = (state, id) => toFormValues(getAtId(state, id)); //
