const KEYS = [
  'title',
  'subtitle',
  'url',
  'issn',
  'issn_e',
  'oclc',
  'cities',
  'region',
  'country',
  'institution',
  'founded_year',
  'ended_year',
  'format',
  'frequency',
  'partitioning',
  'has_issues',
  'entry_method',
  'lookup_source',
  'lookup_key',
  'lookup_term'
] as string[];

export default function() {
  return KEYS;
}