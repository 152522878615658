import { createSelector } from 'reselect'

const getUsersState = state => state.users;

export const getIds = createSelector(getUsersState, users => users.ids);
export const getById = createSelector(getUsersState, users => users.entities);
export const getActiveIds = createSelector(getUsersState, users => users.activeIds);
export const getNewIds = createSelector(getUsersState, users => users.newIds);

export const getIsAdding = createSelector(getUsersState, users => users.isAdding);
export const getIsRequesting = createSelector(getUsersState, users => users.isRequesting);

export const getPagination = createSelector(getUsersState, users => users.pagination);

export const getActive = createSelector(
  [getById, getActiveIds], (entities, ids) => ids.map(id => entities[id])
);

const getNew = createSelector(
  [getById, getNewIds], (entities, ids) => ids.map(id => entities[id])
);

export const getHasActiveUsers = createSelector(getActive, users => users.length > 0);

export const getIsActiveId = (state, id) => getActiveIds(state).includes(id);

export const getUserAtId = (state, id) => getById(state)[id] || {};
export const getHasUser = (state, id) => getIds(state).includes(id);
export const getRecordsForId = (state, id) => getUserAtId(state, id)?.records ?? {};
export const getHasRecordsForId = (state, id) => Object.keys(getRecordsForId(state, id)).length > 0;

export const getInitialValues = (state, id) => {
  const user = getUserAtId(state, id);

  return user && {
    email: user.email,
    first_name: user.first_name,
    id: user.id,
    is_superuser: user.is_superuser,
    last_name: user.last_name,
    username: user.username,
  };
};

export const getNewUser = state => getNew(state)[0]; // bad
export const getHasNewUser = state => getIsAdding(state) && getNewIds(state).length > 0; // bad
export const getIsNewId = (state, id) => getNewIds(state).includes(id);
