const KEYS = [
  'title',
  'subtitle',
  'series_title',
  'series_subtitle',
  'series_paginated_by',
  'isbn',
  'oclc',
  'lccn',
  'copyright_year',
  'publisher_city',
  'publisher_region',
  'publisher_country',
  'published_year',
  'publisher',
  'edition',
  'number_in_series',
  'partitioning',
  'reference',
  'page_count',
  'running_time',
  'format',
  'is_collection',
  'vendor',
  'lookup_source',
  'lookup_key',
  'lookup_term'
] as string[];

export default function() {
  return KEYS;
}
