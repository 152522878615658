const setClasses = (...args) =>
  args.filter(Boolean).map(arg => {
    if (typeof arg === 'string') {
      return arg;
    } else {
      return Object.keys(arg).filter(c => Boolean(arg[c]))
    }
  }).flat().map(c => c.trim()).join(' ');

export default setClasses;
