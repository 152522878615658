import React, { ReactNode } from 'react';

import setClasses from '../lib/dom/set-classes';

interface Props {
  children: ReactNode;
  className?: string;
}

const SectionPrimary = ({ children, className }: Props) => {
  return (
    <div className={setClasses('section-primary', className)}>
      {children}
    </div>
  );
};

export default SectionPrimary;
