import {
  activate,
} from '../reducers/note-comment-tasks';

export default function(store, action) {
  const { dispatch } = store;
  const { isNew, noteCommentTask } = action;

  if (isNew) {
    setTimeout(() => {
      dispatch(activate(noteCommentTask.id));
    }, 250);
  } else {
    dispatch(activate(noteCommentTask.id));
  }
}
