import { v4 as UUID } from 'uuid';

import { create } from '../lib/redux-tools';

import type { AppDispatch } from '../stores/types';
import type { LabelObject } from '../types';

type BreakpointsState = Record<string, boolean>;
type LabelsState = Record<string, LabelObject>;

type ModalState = {
  contents?: {
    type: string;
    id: typeof UUID;
  };
  isActive: boolean;
  kindOf?: string;
};

type State = {
  breakpoints: BreakpointsState;
  labels: LabelsState;
  modal: ModalState;
};

const BREAKPOINTS = {
  mobileTiny: 320,
  mobileSmall: 420,
  mobile: 480,
  standard: 696,
  wide: 800,
  superwide: 1120,
  ultrawide: 1530,
} as Record<string, number>;

const hasHitBreakpoint = (value: number) => window.innerWidth >= value;

const assignBreakpoints = () => Object.entries(BREAKPOINTS)
  .reduce((all, [key, width]) => {
    all[key] = hasHitBreakpoint(width);

    return all;
  }, {} as BreakpointsState);

export const SET_BREAKPOINTS = 'SET_BREAKPOINTS';
export const SET_IS_ADDING_SOURCE = 'SET_IS_ADDING_SOURCE';
export const SET_LABELS = 'SET_LABELS';
export const SET_MODAL_CONTENTS = 'SET_MODAL_CONTENTS';
export const SET_MODAL_IS_ACTIVE = 'SET_MODAL_IS_ACTIVE';
export const SET_MODAL_KIND_OF = 'SET_MODAL_KIND_OF';

export const setBreakpoints = () => ({
  type: SET_BREAKPOINTS,
});

export const setIsAddingSource = (isAddingSource: boolean) => ({
  type: SET_IS_ADDING_SOURCE, isAddingSource,
});

export const setLabels = (labels: LabelsState) => ({
  type: SET_LABELS, labels,
});

export const setModalContents = (contents: ModalState['contents']) => ({
  type: SET_MODAL_CONTENTS, contents,
});

export const setModalIsActive = (isActive: ModalState['isActive'] = false) => ({
  type: SET_MODAL_IS_ACTIVE, isActive,
});

export const setModalKindOf = (kindOf: ModalState['kindOf']) => ({
  type: SET_MODAL_KIND_OF, kindOf,
});

export const removeModal = () => (dispatch: AppDispatch) => {
  dispatch(setModalIsActive(false));
  dispatch(setModalKindOf(null));
  dispatch(setModalContents(null));
};

const initialState = {
  breakpoints: assignBreakpoints(),
  labels: {},
  modal: {
    contents: null,
    isActive: false,
    kindOf: null,
  },
} as State;

export default create({
  [SET_BREAKPOINTS]: (state: State) => ({
    ...state,
    breakpoints: {
      ...state.breakpoints,
      ...assignBreakpoints(),
    },
  }),

  [SET_LABELS]: (state: State, { labels }: { labels: LabelsState }) => ({
    ...state,
    labels: {
      ...state.labels,
      ...labels,
    }
  }),

  [SET_MODAL_CONTENTS]: (state: State, { contents }: { contents: ModalState['contents'] }) => ({
    ...state,
    modal: {
      ...state.modal,
      contents: contents,
    }
  }),

  [SET_MODAL_IS_ACTIVE]: (state: State, { isActive }: { isActive: ModalState['isActive'] }) => ({
    ...state,
    modal: {
      ...state.modal,
      isActive,
    }
  }),

  [SET_MODAL_KIND_OF]: (state: State, { kindOf }: { kindOf: ModalState['kindOf'] }) => ({
    ...state,
    modal: {
      ...state.modal,
      kindOf,
    }
  }),
}, initialState);
