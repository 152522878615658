const KEYS = [
  'title',
  'year',
  'month',
  'day',
  'period',
  'format',
  'published_date'
] as string[];

export default function() {
  return KEYS;
}
