import without from '../lib/array/without';
import { endEditingOnSource } from '../reducers/source-tags';
import {
  getSourceTagAtId,
  getSourceTagsForSourceId,
} from '../selectors/source-tags';

export default function(store, action) {
  const { dispatch, getState } = store;
  const sourceTag = getSourceTagAtId(getState(), action.id);
  const sourceId = sourceTag.source_id;
  const sourceTags = getSourceTagsForSourceId(getState(), sourceId);
  const nextSourceTags = without(sourceTags, sourceTag);

  if (nextSourceTags.length === 0) {
    dispatch(endEditingOnSource(sourceId, nextSourceTags));
  }
}
