import { getCurrentRoute } from '../lib/location';
import { create } from '../lib/redux-tools';

const SET_ACTIONS = 'SET_ACTIONS';
const SET_SECTIONS = 'SET_SECTIONS';
const ENV_SET_CURRENT = 'ENV_SET_CURRENT';
export const INIT_ALL = 'INIT_ALL';
const SET_FEATURES = 'SET_FEATURES';
const SET_LOCALES = 'SET_LOCALES';

export const init = () => ({ type: INIT_ALL });

export const setActions = (actions) => ({
  type: SET_ACTIONS, actions,
});

export const setCurrent = (current) => ({
  type: ENV_SET_CURRENT, current,
});

export const setFeatures = (features) => ({
  type: SET_FEATURES, features,
});

export const setLocales = (locales) => ({
  type: SET_LOCALES, locales,
});

export const setSections = (sections) => ({
  type: SET_SECTIONS, sections,
});

const routeOverrides = {
  login: {
    actions: ['index', 'password'],
  },
  settings: {
    actions: ['index', 'password'],
  },
};

const initialState = {
  action: {},
  current: {
    ...getCurrentRoute(routeOverrides),
    user: {}
  },
  features: {},
  locales: [],
  section: {},
};

export default create({
  [INIT_ALL]: () => initialState,
  [ENV_SET_CURRENT]: (state, { current }) => {
    return {
      ...state,
      current: {
        ...state.current,
        ...current
      }
    }
  },
  [SET_ACTIONS]: (state, { actions }) => {
    return {
      ...state,
      action: actions,
    }
  },
  [SET_FEATURES]: (state, { features }) => {
    return {
      ...state,
      features,
    }
  },
  [SET_LOCALES]: (state, { locales }) => {
    return {
      ...state,
      locales,
    }
  },
  [SET_SECTIONS]: (state, { sections }) => {
    return {
      ...state,
      section: sections,
    }
  },
}, initialState);
