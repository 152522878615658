import React, { ReactNode } from 'react';

import setClasses from '../../lib/dom/set-classes';

import InfoBug from '../info-bug';
import FieldLabel from './field-label';

interface Props {
  children: ReactNode;
  className?: string;
  inline?: boolean;
  size?: 'small' | 'large';
  type?: string;
  hasLabel?: boolean;
  helper?: string;
  name?: string;
  label?: string;
  width?: 'eighth' | 'quarter' | 'half' | 'three-quarters' | 'full';
}

const FieldItem = ({
  children,
  className,
  inline,
  size,
  type = 'input',
  hasLabel,
  helper,
  name,
  label,
  width,
}: Props) => {
  const isSelect = type === 'select';
  const isBoolean = type === 'boolean';

  return (
    <div
      className={setClasses(
        'item-field',
        inline && 'item-field-inline',
        width && `item-field-${width}`,
        size && `item-field-${size}`,
        (isSelect || isBoolean) && `item-field-${type}`,
        className
      )}
    >
      {label && (name || hasLabel) && (
        <FieldLabel name={name}>
          {label}{helper && <InfoBug content={helper} />}
        </FieldLabel>
      )}
      {children}
    </div>
  );
};

export default FieldItem;
