import { createSelector } from 'reselect'

import toFormValues from '../adapters/person-tag/to-form-values';
import getStringsForNullValues from '../lib/object/get-strings-for-null-values';

import { getById as getPeopleById } from './people';

const getPersonTagsState = state => state.personTags;

const getById = createSelector(getPersonTagsState, personTags => personTags.entities);
const getIds = createSelector(getPersonTagsState, personTags => personTags.ids);
export const getActiveIds = createSelector(getPersonTagsState, personTags => personTags.activeIds);
export const getEditingIds = createSelector(getPersonTagsState, personTags => personTags.editingIds);
const getNewIds = createSelector(getPersonTagsState, personTags => personTags.newIds);

const getPersonTags = createSelector(
  [getIds, getById], (ids, entities) => ids.map(id => entities[id])
);

const getNewPersonTags = createSelector(
  [getNewIds, getById], (ids, entities) => ids.map(id => entities[id])
);

export const getPersonTagsForPersonId = (state, personId) =>
  getPersonTags(state).filter(personTag => personTag.person_id === personId);

export const getNewPersonTagForPersonId = (state, personId) =>
  getNewPersonTags(state).find(personTag => personTag.person_id === personId);

export const getTagIsSetOnPerson = (state, personId, tagId) =>
  getPersonTagsForPersonId(state, personId).map(personTag => personTag.tag_id).includes(tagId);

export const getIsNewId = (state, id) => getNewIds(state).includes(id);

export const getPersonTagAtId = (state, id) => getById(state)[id] || {};
export const getInitialState = (state, id) => toFormValues(getStringsForNullValues(getPersonTagAtId(state, id)));
export const getValues = (state, id) => toFormValues(getPersonTagAtId(state, id));

export const getPeopleByTagId = createSelector(
  [getPersonTags, getPeopleById], (personTags, peopleById) => {
    return personTags.reduce((peopleByTagId, personTag) => {
      const { person_id, tag_id } = personTag;

      if (!(tag_id in peopleByTagId)) {
        peopleByTagId[tag_id] = [];
      }

      peopleByTagId[tag_id].push(peopleById[person_id]);

      return peopleByTagId;
    }, {});
  }
);
