export const INTERNAL_LOOKUP_BOOKS = '/lookup/books';
export const INTERNAL_LOOKUP_JOURNALS = '/lookup/journals';
export const INTERNAL_LOOKUP_MAGAZINES = '/lookup/magazines';
export const INTERNAL_LOOKUP_NEWSPAPERS = '/lookup/newspapers';
export const INTERNAL_LOOKUP_PERIODICALS = '/lookup/periodicals';

export const OPEN_LIBRARY_SEARCH = 'https://openlibrary.org/search.json';
export const OPEN_LIBRARY_BOOKS_ISBN = 'https://openlibrary.org/api/books';

export const OPEN_LIBRARY_USE_BOOKS_ISBN = true;
