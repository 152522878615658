import React from 'react';

import Actions from '../item-actions';
import Action from './action';
import Checkmark from '../images/ui/checkmark';
import FieldItem from '../form/field-item';

const Login = () => {
  return (
    <form className='item-details item-panel' name='login' method='post' action='/login/'>
      <fieldset className='item-header-and-meta'>
        <div className='item-header'>
          <h3>Sign in.</h3>
        </div>

        <div className='item-meta'>
          <Actions size='large'>
            <Action
              actionRequired={true}
              isDisabled={false}
              title='Sign in'
              type='save'
            >
              <Checkmark />
            </Action>
          </Actions>
        </div>
      </fieldset>

      <fieldset className='item-description'>
        <FieldItem name='login-username' label='Email address'>
          <input
            type='text'
            name='login[username]'
            id='login-username'
            autoFocus
          />
        </FieldItem>

        <FieldItem name='login-password' label='Password'>
          <input
            type='password'
            name='login[password]'
            id='login-password'
          />
        </FieldItem>
      </fieldset>
    </form>
  );
};

export default Login;
