import { SECTION_NOTE_TAGS } from '../constants.js';

import uniq from '../lib/array/uniq';
import withoutProp from '../lib/object/without';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeAllEntities,
  mergeEntityValues,
  setUniqueIds,
  toggleId,
} from '../lib/redux-tools';
import { deletePost, post } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes } from '../lib/route';

import { setWarning } from '../actions/messages';

import buildNewNoteTag from '../adapters/note-tag/build-new';
import toPost from '../adapters/note-tag/to-post';
import { buildNewTag } from '../adapters/tags';

import {
  INIT_NOTES,
  SET_IS_ADDING_TAG_TO_NOTE,
  SET_IS_EDITING_TAGS_ON_NOTE,
} from './notes';
import {
  deleteItem as deleteTag,
  saveNewWithValue as saveNewTagWithValue,
  set as setTag,
} from './tags';

import { getIsNewId, getValues } from '../selectors/note-tags';
import { getMatchingTag } from '../selectors/tags';
import { getTagIsSetOnNote } from '../selectors/note-tags';

export const ACTIVATE_NOTE_TAG = 'ACTIVATE_NOTE_TAG';
const ACTIVATE_NOTE_TAGS = 'ACTIVATE_NOTE_TAGS';
export const DEACTIVATE_NOTE_TAG = 'DEACTIVATE_NOTE_TAG';
export const DELETE_NOTE_TAG = 'DELETE_NOTE_TAG';
export const SET_NOTE_TAG = 'SET_NOTE_TAG';
export const SET_NOTE_TAGS = 'SET_NOTE_TAGS';

const routes = getRoutes(SECTION_NOTE_TAGS);

export const activate = (idOrIds) =>
  Array.isArray(idOrIds)
    ? { type: ACTIVATE_NOTE_TAGS, ids: idOrIds }
    : { type: ACTIVATE_NOTE_TAG, id: idOrIds };

export const beginAddingToNote = (noteId) =>
  setIsAddingToNote(noteId, true);

export const beginEditingOnNote = (noteId, ids) =>
  setIsEditingOnNote(noteId, true, ids);

export const createNew = noteId => dispatch => {
  const tag = buildNewTag();
  const noteTag = buildNewNoteTag(noteId, tag.id);

  Promise.resolve(
    dispatch(setTag(tag, true)),
    dispatch(set(noteTag, true))
  ).then(() => {
    dispatch(beginAddingToNote(noteId));
  }).then(() => {
    dispatch(activate(noteTag.id));
  });
};

export const deactivate = (id) => ({
  type: DEACTIVATE_NOTE_TAG, id,
});

export const deleteItem = (id) => ({
  type: DELETE_NOTE_TAG, id,
});

export const endAddingToNote = (noteId) =>
  setIsAddingToNote(noteId, false);

export const endEditingOnNote = (noteId, ids) =>
  setIsEditingOnNote(noteId, false, ids);

export const warnIsDuplicate = (value) =>
  setWarning(`You’ve already tagged this note with “${value}”`);

export const removeUnsaved = (itemId, id, tagId) => dispatch => {
  Promise.resolve(
    dispatch(deactivate(id))
  ).then(() => {
    setTimeout(() => {
      dispatch(endAddingToNote(itemId));
      dispatch(deleteItem(id));
      dispatch(deleteTag(tagId));
    }, 400);
  });
};

export const remove = id => dispatch => {
  // todo: nope
  Promise.resolve(
    dispatch(deactivate(id))
  ).then(() => {
    deletePost(routes.detail(id))
      .then(() => {
        dispatch(deleteItem(id));
      })
      .catch(() => {
        dispatch(setWarning());
      });
  });
};

export const saveNew = (_id, values) => dispatch =>
  post(routes.new(), toPost(_id, values))
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const saveNewWithId = (_id, tagId, values) => dispatch => {
  let newValues = values;

  newValues = withoutProp(newValues, 'id');
  newValues = withoutProp(newValues, 'title');
  newValues = { ...newValues, tag_id: tagId };

  return dispatch(saveNew(_id, newValues))
    .then(result => {
      const { data } = result;
      const { note_tag } = data;

      dispatch(set(note_tag.record));
      dispatch(activate(note_tag.record.id));

      return result;
    });
};

const saveNewWithTitle = (title, values) => dispatch =>
  dispatch(saveNewTagWithValue(title))
    .then(result => {
      const { data } = result;
      const { tag } = data;

      dispatch(setTag(tag.record));

      dispatch(saveNew(values.id, {
        ...values,
        tag_id: tag.record.id,
      }))
        .then(result => {
          const { data } = result;
          const { note_tag } = data;

          dispatch(set(note_tag.record));
          dispatch(activate(note_tag.record.id));
        });

      return result;
    });

export const save = (_id, formValues) => (dispatch, getState) => {
  const state = getState();
  
  const isNewNoteTag = getIsNewId(state, _id);
  
  if (isNewNoteTag) {
    const values = getValues(state, _id);
    const { title } = formValues;
    const matchingTag = getMatchingTag(state, title);

    if (matchingTag) {
      const { note_id } = values;
      const tagIsSetOnNote = getTagIsSetOnNote(state, note_id, matchingTag.id);

      if (tagIsSetOnNote) {
        return dispatch(warnIsDuplicate(title));
      } else {
        return dispatch(saveNewWithId(_id, matchingTag.id, values));
      }
    } else {
      return dispatch(saveNewWithTitle(title, values));
    }
  } else {
    return dispatch({ type: "NOOP" });
  }
};

export const set = (noteTag, isNew = false) => ({
  type: SET_NOTE_TAG, noteTag, isNew,
});

export const setAll = (noteTags) => ({
  type: SET_NOTE_TAGS, noteTags,
});

export const setIsAddingToNote = (noteId, isAddingTag) => ({
  type: SET_IS_ADDING_TAG_TO_NOTE, id: noteId, isAddingTag,
});

export const setIsEditingOnNote = (noteId, isEditingTags, ids) => ({
  type: SET_IS_EDITING_TAGS_ON_NOTE, id: noteId, isEditingTags, ids,
});

const additionalIdFields = [
  'activeIds',
  'editingIds',
  'newIds',
];

const initialState = getInitialState({}, additionalIdFields);

export default create({
  [INIT_NOTES]: () => initialState,

  [SET_NOTE_TAGS]: (state, { noteTags }) => ({
    ...state,
    entities: mergeAllEntities(state, noteTags),
    ids: setUniqueIds(state, noteTags),
  }),

  [SET_NOTE_TAG]: (state, { noteTag, isNew }) => ({
    ...state,
    entities: mergeEntityValues(state, noteTag),
    ids: isNew
      ? state.ids
      : uniq([ ...state.ids, noteTag.id ]),
    newIds: isNew
      ? uniq([ ...state.newIds, noteTag.id ])
      : state.newIds,
  }),

  [SET_IS_EDITING_TAGS_ON_NOTE]: (state, { ids, isEditingTags }) => toggleId(state, ids, 'editingIds', isEditingTags),
  [ACTIVATE_NOTE_TAG]: (state, { id }) => toggleId(state, id, 'activeIds', true),
  [ACTIVATE_NOTE_TAGS]: (state, { ids }) => toggleId(state, ids, 'activeIds', true),
  [DEACTIVATE_NOTE_TAG]: (state, { id }) => toggleId(state, id, ['activeIds', 'editingIds'], false),
  [DELETE_NOTE_TAG]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
}, initialState);
