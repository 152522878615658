import {
  SECTION_LOOKUP,
  SECTION_SOURCES,
} from '../constants.js';

import changeStep from '../actions/lookup/step-change';

import adaptPagination from '../adapters/result/pagination';
import toPost from '../adapters/source/to-post';

import { create } from '../lib/redux-tools';
import { get, post } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes, redirectTo } from '../lib/route';

import { getStep } from '../selectors/lookup';

import { SET_MODAL_CONTENTS } from './ui';

const LOOKUP_ACTIVATE_FORM = 'LOOKUP_ACTIVATE_FORM';
const LOOKUP_DEACTIVATE_FORM = 'LOOKUP_DEACTIVATE_FORM';
const LOOKUP_DEACTIVATE_QUESTION = 'LOOKUP_DEACTIVATE_QUESTION';
const LOOKUP_SET_OPEN_LIBRARY_STATUS = 'LOOKUP_SET_OPEN_LIBRARY_STATUS';
const LOOKUP_SET_SELECTED_RESULT = 'LOOKUP_SET_SELECTED_RESULT';
const LOOKUP_SET_STEP = 'LOOKUP_SET_STEP';
export const LOOKUP_STEP_BACK = 'LOOKUP_STEP_BACK';
export const LOOKUP_ACTIVATE_CHOICES = 'LOOKUP_ACTIVATE_CHOICES';
export const LOOKUP_ACTIVATE_QUESTION = 'LOOKUP_ACTIVATE_QUESTION';
export const LOOKUP_ACTIVATE_RESULTS = 'LOOKUP_ACTIVATE_RESULTS';
export const LOOKUP_BEGIN_SEARCH = 'LOOKUP_BEGIN_SEARCH';
export const LOOKUP_CLEAR_FORM = 'LOOKUP_CLEAR_FORM';
export const LOOKUP_CLEAR_RESULTS = 'LOOKUP_CLEAR_RESULTS'; // cut cut cut
export const LOOKUP_DEACTIVATE_CHOICES = 'LOOKUP_DEACTIVATE_CHOICES';
export const LOOKUP_END_SEARCH = 'LOOKUP_END_SEARCH';
export const LOOKUP_GET_QUESTION = 'LOOKUP_GET_QUESTION';
export const LOOKUP_GET_RESULTS = 'LOOKUP_GET_RESULTS';
export const LOOKUP_SAVE_RECORD = 'LOOKUP_SAVE_RECORD';
export const LOOKUP_SET_QUESTION = 'LOOKUP_SET_QUESTION';
export const LOOKUP_SET_RESULTS = 'LOOKUP_SET_RESULTS';
export const LOOKUP_SET_SEARCH_TERMS = 'LOOKUP_SET_SEARCH_TERMS';
export const LOOKUP_SKIP = 'LOOKUP_SKIP';
export const LOOKUP_UPDATE_RESULT = 'LOOKUP_UPDATE_RESULT';
export const SET_LOADING = 'SET_LOADING';
export const SET_PROMPT = 'SET_PROMPT';

const routes = getRoutes(SECTION_LOOKUP);

export const activateChoices = () => ({
  type: LOOKUP_ACTIVATE_CHOICES,
});

export const activateForm = (form) => ({
  type: LOOKUP_ACTIVATE_FORM, form,
});

export const activateQuestion = () => ({
  type: LOOKUP_ACTIVATE_QUESTION,
});

export const activateResults = () => ({
  type: LOOKUP_ACTIVATE_RESULTS,
});

export const beginSearch = () => ({
  type: LOOKUP_BEGIN_SEARCH,
});

export const clearForm = () => ({
  type: LOOKUP_CLEAR_FORM,
});

export const clearResults = () => ({
  type: LOOKUP_CLEAR_RESULTS,
});

export const configureQuestion = data => dispatch => {
  const { choices, mode } = data;

  dispatch(setQuestion(mode, choices));
  dispatch(activateQuestion());
  setTimeout(() => dispatch(activateChoices()), 600);
};

export const deactivateChoices = () => ({
  type: LOOKUP_DEACTIVATE_CHOICES,
});

export const deactivateForm = () => ({
  type: LOOKUP_DEACTIVATE_FORM,
});

export const endSearch = () => ({
  type: LOOKUP_END_SEARCH,
});

export const getQuestion = () => ({
  type: LOOKUP_GET_QUESTION,
});

export const getResults = () => ({
  type: LOOKUP_GET_RESULTS,
});

export const lookupPlace = query =>
  get(`/lookup/place?query=${encodeURI(query)}`)
    .catch(error => {
      console.log('uh oh,',error);
    });

export const lookupReference = (scheme, reference) =>
  get(`${routes.reference()}?scheme=${scheme}&reference=${encodeURI(reference)}`)
    .catch(error => {
      console.log('uh oh,',error);
    });

export const saveRecord = (values) => ({
  type: LOOKUP_SAVE_RECORD, values,
});

export const setLoading = () => ({
  type: SET_LOADING,
});

export const setOpenLibraryStatus = (isActive) => ({
  type: LOOKUP_SET_OPEN_LIBRARY_STATUS, isActive,
});

export const setPrompt = (message) => ({
  type: SET_PROMPT, message,
});

export const setQuestion = (mode, choices) => ({
  type: LOOKUP_SET_QUESTION, mode, choices,
});

export const setResults = (data) => {
  const { page, pagination, results, total } = data;
  const { pageCount, perPage } = adaptPagination(total);

  return {
    type: LOOKUP_SET_RESULTS,
    page,
    pageCount,
    pagination,
    perPage,
    results,
  };
};

export const setSelectedResult = (step, resultId) => ({
  type: LOOKUP_SET_SELECTED_RESULT, step, resultId,
});

export const getResultsPage = (page) => (dispatch, getState) => {
  const { step, terms } = getStep(getState());

  dispatch(changeStep(step, {
    terms: {
      page,
      query: terms.at(0),
    },
  }));
};

export const setStep = (step, values) => {
  // todo: move up the chain
  if (!('skippable' in values)) {
    values.skippable = false;
  }

  return { type: LOOKUP_SET_STEP, step, ...values };
};

export const skipResults = next => dispatch => {
  dispatch(clearResults());
  dispatch(skipStep());
  dispatch(changeStep(next));
};

export const skipStep = () => ({
  type: LOOKUP_SKIP,
});

// todo: better
export const start = () =>
  changeStep(
    'start',
    {
      mode: 'waiting',
      prompt: {
        message: 'one moment ...'
      }
    }
  );

export const stepBack = () => ({
  type: LOOKUP_STEP_BACK,
});

export const submit = values => dispatch => {
  const sourceRoutes = getRoutes(SECTION_SOURCES);
  const record = toPost(values);

  dispatch(saveRecord(record));

  return post(sourceRoutes.new(), record)
    .then(result => {
      const { data } = result;
      const { source } = data;

      setTimeout(() => {
        redirectTo(sourceRoutes.detail(source.record.id));
      }, 1200);

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));
};

export const updateResult = (id, values) => ({
  type: LOOKUP_UPDATE_RESULT, id, values,
});

const initialFormState = {
  isActive: false,
  isEditable: false,
};

const initialQuestionState = {
  isActive: false,
  showChoices: false,
};

const initialPromptState = {
  message: null,
  mode: null,
};

const initialResultsState = {
  isActive: false,
  page: 1,
  pageCount: 1,
  showResults: false,
};

const initialState = {
  ['api:OpenLibrary']: false,
  form: initialFormState,
  prompt: initialPromptState,
  question: initialQuestionState,
  results: initialResultsState,
  sourceId: null,
  steps: [],
  stepResults: [],
};

export default create({
  [SET_LOADING]: state => ({
    ...state,
    prompt: {
      ...state.prompt,
      message: 'Waiting ...',
      mode: 'waiting',
    },
  }),

  [SET_PROMPT]: (state, { message }) => ({
    ...state,
    prompt: {
      ...state.prompt,
      message,
    },
  }),

  // [LOOKUP_GET_QUESTION]: (state, { question }) => ({
  //   ...state,
  //   question: {
  //     ...state.question,
  //   },
  // }),

  // [LOOKUP_SET_QUESTION]: (state, { mode, choices }) => ({
  //   ...state,
  //   question: {
  //     ...state.question,
  //     // mode,
  //     // choices,
  //   },
  // }),

  [SET_MODAL_CONTENTS]: (state, { contents }) => {
    if (contents?.type === 'source') {
      return {
        ...state,
        sourceId: contents.id,
      };
    } else if (contents === null) {
      return {
        ...state,
        sourceId: null,
      };
    } else {
      return state;
    }
  },

  [LOOKUP_ACTIVATE_QUESTION]: state => ({
    ...state,
    question: {
      ...state.question,
      isActive: true,
    },
  }),

  [LOOKUP_DEACTIVATE_QUESTION]: state => ({
    ...state,
    question: {
      ...state.question,
      isActive: false,
    },
  }),

  [LOOKUP_ACTIVATE_CHOICES]: state => ({
    ...state,
    question: {
      ...state.question,
      showChoices: true,
    },
  }),

  [LOOKUP_DEACTIVATE_CHOICES]: state => ({
    ...state,
    question: {
      ...state.question,
      showChoices: false,
    },
  }),

  [LOOKUP_ACTIVATE_RESULTS]: state => ({
    ...state,
    results: {
      ...state.results,
      isActive: true,
      showResults: true,
    }
  }),

  [LOOKUP_SET_STEP]: (state, data) => {
    return {
      ...state,
      prompt: initialPromptState,
      steps: [
        ...state.steps,
        data,
      ]
    };
  },

  [LOOKUP_STEP_BACK]: state => {
    const newSteps = state.steps.slice(0, -1);
    const step = newSteps.at(-1);
    const isQuestion = step.mode === 'question';
    const hasChoices = step.choices?.length > 0;

    return {
      ...state,
      question: isQuestion && hasChoices
        ? { ...state.question, isActive: true, showChoices: true, }
        : { ...initialQuestionState },
      steps: [
        ...newSteps,
      ],
    };
  },

  [LOOKUP_CLEAR_RESULTS]: state => ({
    ...state,
    results: initialResultsState,
  }),

  [LOOKUP_GET_RESULTS]: state => ({
    ...state,
    results: {
      ...state.results,
      isActive: true,
      showResults: true,
    },
  }),

  [LOOKUP_SET_RESULTS]: (state, { pagination }) => {
    return {
      ...state,
      results: {
        ...state.results,
        pagination,
      },
    };
  },

  [LOOKUP_SET_SELECTED_RESULT]: (state, { step, resultId }) => {
    return {
      ...state,
      stepResults: [
        ...state.stepResults,
        {
          step,
          resultId,
        },
      ],
    };
  },

  [LOOKUP_SET_OPEN_LIBRARY_STATUS]: (state, { isActive }) => ({
    ...state,
    ['api:OpenLibrary']: isActive,
  }),

  [LOOKUP_ACTIVATE_FORM]: state => ({
    ...state,
    form: {
      ...state.form,
      isActive: true,
    },
  }),

  [LOOKUP_DEACTIVATE_FORM]: state => ({
    ...state,
    form: {
      ...state.form,
      isActive: false,
    },
  }),

  [LOOKUP_END_SEARCH]: state => ({
    ...state,
    form: initialFormState,
  }),

  [LOOKUP_CLEAR_FORM]: state => ({
     ...state,
     ...initialState,
     ['api:OpenLibrary']: state['api:OpenLibrary']
  })
}, initialState);
