import { SECTION_COMMENTS } from '../constants.js';

import uniq from '../lib/array/uniq';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeAllEntities,
  mergeEntityValues,
  setUniqueIds,
  toggleId,
} from '../lib/redux-tools';
import { deletePost, post, put } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes } from '../lib/route';

import adapt from '../adapters/comment/adapt';
import buildNew from '../adapters/comment/build-new';
import toPost from '../adapters/comment/to-post';
import toPut from '../adapters/comment/to-put';

import {
  INIT_NOTES,
  beginAddingComment as beginAddingCommentToNote,
  endAddingComment as endAddingCommentToNote,
} from './notes';

export const ACTIVATE_COMMENT = 'ACTIVATE_COMMENT';
const ACTIVATE_COMMENTS = 'ACTIVATE_COMMENTS';
export const DEACTIVATE_COMMENT = 'DEACTIVATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const EXIT_EDIT_COMMENT = 'EXIT_EDIT_COMMENT';
export const SET_COMMENT = 'SET_COMMENT';
export const SET_COMMENTS = 'SET_COMMENTS';
export const SET_IS_ADDING_TASK_TO_NOTE_COMMENT = 'SET_IS_ADDING_TASK_TO_NOTE_COMMENT';
export const SET_IS_EDITING_TASK_ON_NOTE_COMMENT = 'SET_IS_EDITING_TASK_ON_NOTE_COMMENT';
export const SET_IS_EDITING_TASKS_ON_NOTE_COMMENT = 'SET_IS_EDITING_TASKS_ON_NOTE_COMMENT';

const routes = getRoutes(SECTION_COMMENTS);

export const activate = (idOrIds) =>
  Array.isArray(idOrIds)
    ? { type: ACTIVATE_COMMENTS, ids: idOrIds }
    : { type: ACTIVATE_COMMENT, id: idOrIds };

export const createNew = (noteId, locale) => dispatch => {
  const comment = buildNew(noteId, locale);

  dispatch(beginAddingCommentToNote(noteId));

  Promise.resolve(
    dispatch(set(comment, true))
  ).then(action => {
    const { comment } = action;

    dispatch(activate(comment.id))
  });
};

export const deactivate = (id) => ({
  type: DEACTIVATE_COMMENT, id,
});

export const deleteItem = (id) => ({
  type: DELETE_COMMENT, id,
});

export const edit = (id) => ({
  type: EDIT_COMMENT, id,
});

export const exitEdit = (id) => ({
  type: EXIT_EDIT_COMMENT, id,
});

export const removeExisting = id => dispatch =>
  deletePost(routes.remove(id))
    .then(result => {
      dispatch(deactivate(id));
      setTimeout(() => {
        dispatch(deleteItem(id));
      }, 400);

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const removeNew = (id, note_id) => dispatch => {
  dispatch(deactivate(id));
  dispatch(endAddingCommentToNote(note_id));

  setTimeout(() => dispatch(deleteItem(id)), 400);
};

const saveExisting = (id, values) => dispatch =>
  put(routes.detail(id), values)
    .then(result => {
      const { data } = result;
      const { comment } = data;

      const adapted = adapt(comment.record);

      dispatch(exitEdit(adapted.id));
      dispatch(set(adapt(adapted)));

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

const saveNew = (uuid, values) => dispatch =>
  post(routes.new(), values)
    .then(result => {
      const { data } = result;
      const { comment } = data;

      const adapted = adapt(comment.record);

      dispatch(exitEdit(uuid));
      dispatch(deactivate(uuid));
      dispatch(deleteItem(uuid));
      dispatch(set(adapted));
      dispatch(activate(adapted.id));
      dispatch(endAddingCommentToNote(adapted.note_id));

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const save = (id, note_id, values, isNew) => {
  const adapter = isNew ? toPost : toPut;
  const method = isNew ? saveNew : saveExisting;
  const data = adapter(id, note_id, values);

  return method(id, data);
};

export const setAll = comments => ({
  type: SET_COMMENTS, comments,
});

export const set = (comment, isNew = false) => ({
  type: SET_COMMENT, comment, isNew,
});

const additionalIdFields = [
  'activeIds',
  'editingIds',
  'isAddingTaskToIds',
  'isEditingTasksOnIds',
  'newIds',
];

const initialState = getInitialState({}, additionalIdFields);

export default create({
  [INIT_NOTES]: () => initialState,

  [SET_COMMENTS]: (state, { comments }) => ({
    ...state,
    entities: mergeAllEntities(state, comments),
    ids: setUniqueIds(state, comments),
  }),

  [SET_COMMENT]: (state, { comment, isNew }) => ({
    ...state,
    entities: mergeEntityValues(state, comment),
    ids: uniq([ ...state.ids, comment.id ]),
    editingIds: isNew
      ? uniq([ ...state.editingIds, comment.id ])
      : state.editingIds,
    newIds: isNew
      ? uniq([ ...state.newIds, comment.id ])
      : state.newIds,
  }),

  [SET_IS_ADDING_TASK_TO_NOTE_COMMENT]: (state, { id, isAddingTask }) => toggleId(state, id, 'isAddingTaskToIds', isAddingTask),
  [SET_IS_EDITING_TASK_ON_NOTE_COMMENT]: (state, { id, isEditingTask }) => toggleId(state, id, 'isEditingTasksOnIds', isEditingTask),
  [SET_IS_EDITING_TASKS_ON_NOTE_COMMENT]: (state, { id, isEditingTasks }) => toggleId(state, id, 'isEditingTasksOnIds', isEditingTasks),

  [EDIT_COMMENT]: (state, { id }) => toggleId(state, id, 'editingIds', true),
  [EXIT_EDIT_COMMENT]: (state, { id }) => toggleId(state, id, 'editingIds', false),
  [ACTIVATE_COMMENT]: (state, { id }) => toggleId(state, id, 'activeIds', true),
  [ACTIVATE_COMMENTS]: (state, { ids }) => toggleId(state, ids, 'activeIds', true),
  [DEACTIVATE_COMMENT]: (state, { id }) => toggleId(state, id, ['activeIds', 'editingIds'], false),
  [DELETE_COMMENT]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
}, initialState);
