import {
  deactivate,
  removeFromRemoveQueue,
} from '../reducers/messages';

export default function(store, action) {
  const { dispatch, getState } = store;

  setTimeout(() => {
    if (getState().messages.idsToRemove.includes(action.id)) {
      dispatch(removeFromRemoveQueue(action.id));
      dispatch(deactivate(action.id));
    }
  }, 6000);
}
