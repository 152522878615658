/* eslint no-unused-vars: 0 */
/* eslint @typescript-eslint/no-unused-vars: 0 */

const del = null;

const without = (obj, property) => {
  const { [property]: del, ...rest } = obj;

  return { ...rest };
};

export default without;
