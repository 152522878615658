import { normalizeEntities } from '../lib/redux-tools';
import without from '../lib/object/without';

import adaptComment from '../adapters/comment/adapt';

import {
  activate as activateComments,
  setAll as setComments,
} from '../reducers/comments';
import {
  activate as activateNoteTags,
  setAll as setNoteTags,
} from '../reducers/note-tags';
import { update as updateTag } from '../reducers/tags';
import {
  activate as activateTasks,
  setAll as setTasks,
} from '../reducers/tasks';
import {
  activate as activateNoteCommentTasks,
  setAll as setNoteCommentTasks,
} from '../reducers/note-comment-tasks';

const setCommentTasks = (comments) => dispatch => {
  const noteCommentsWithTasks = comments.filter(comment => comment.tasks?.length);
  const tasks = noteCommentsWithTasks.flatMap(noteComment => noteComment.tasks
    .map(noteCommentTask => noteCommentTask.task));
  const noteCommentTasks = noteCommentsWithTasks.flatMap(noteComment => {
    return noteComment.tasks.map(noteCommentTask => {
      return {
        created: noteCommentTask.created,
        id: noteCommentTask.id,
        note_comment_id: noteCommentTask.note_comment_id,
        task_id: noteCommentTask.task_id,
      }
    });
  });

  dispatch(setTasks(tasks));
  dispatch(activateTasks(tasks.map(item => item.id)));

  dispatch(setNoteCommentTasks(noteCommentTasks));
  dispatch(activateNoteCommentTasks(noteCommentTasks.map(item => item.id)));
};

export default function(store, action) {
  const { dispatch } = store;
  const { note } = action;

  if (note.comments?.length) {
    dispatch(setComments(normalizeEntities(note.comments.map(adaptComment))));
    dispatch(activateComments(note.comments.map(comment => comment.id)));

    dispatch(setCommentTasks(note.comments));
  }

  if (note.tags?.length) {
    const tagsToUpdate = note.tags.map(noteTag => noteTag.tag);
    const noteTagsToSet = note.tags.map(noteTag => without(noteTag, 'tag'));

    dispatch(setNoteTags(normalizeEntities(noteTagsToSet)));
    dispatch(activateNoteTags(noteTagsToSet.map(noteTag => noteTag.id)));

    tagsToUpdate.forEach(tag => {
      dispatch(updateTag(tag));
    });
  }
}
