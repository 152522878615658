import {
  SECTION_COMMENTS,
  SECTION_INDEXES,
  SECTION_LOOKUP,
  SECTION_NOTE_COMMENT_TASKS,
  SECTION_NOTE_SOURCES,
  SECTION_NOTE_TAGS,
  SECTION_NOTES,
  SECTION_PEOPLE,
  SECTION_PERSON_TAGS,
  SECTION_SETTINGS,
  SECTION_SOURCE_TAGS,
  SECTION_SOURCES,
  SECTION_TAGS,
  SECTION_TASKS,
  SECTION_USERS,
} from '../constants.js';
import { kebabCase } from './text/case';

import type { Id } from '../types';

type RouteFunc = () => string;
type RouteFuncWithId = (id: Id) => string;
type RouteFuncWithIdAndKindOf = (id: Id, kindOf?: string) => string;
type RouteFuncWithKindOf = (kindOf: string) => string;
type RouteFuncWithOptionalKindOf = (kindOf?: string) => string;

export type SectionRoutes = {
  base?: RouteFunc;
  children?: RouteFuncWithId;
  detail?: RouteFuncWithId | RouteFuncWithIdAndKindOf;
  edit?: RouteFuncWithId | RouteFuncWithIdAndKindOf;
  kindOf?: RouteFuncWithKindOf;
  'new'?: RouteFuncWithOptionalKindOf;
  notes?: RouteFuncWithId;
  password?: RouteFunc;
  people?: RouteFuncWithId;
  recent_sources?: RouteFunc;
  records?: RouteFuncWithId;
  reference?: RouteFunc;
  remove?: RouteFuncWithId | RouteFuncWithIdAndKindOf;
  sources?: RouteFuncWithId;
  tag?: RouteFuncWithId;
};

export type Section = {
  label?: string;
  routes: SectionRoutes;
  segment?: string;
};

type Sections = Record<string, Section>;
type Action = 'new' | 'detail' | 'edit' | 'remove';
type CustomAction = RouteFunc | RouteFuncWithId | RouteFuncWithIdAndKindOf | RouteFuncWithKindOf | RouteFuncWithOptionalKindOf;

const getSectionRoutes = (namespace: string, actions: Action[], customActions: Record<string, CustomAction> = {}) => {
  const slug = kebabCase(namespace);
  const routeActions: SectionRoutes = {
    base: () => `/${slug}`,
  };

  if (actions.includes('new')) {
    routeActions.new = () => `/${slug}/new`;
  }
  if (actions.includes('detail')) {
    routeActions.detail = (id: Id) => `/${slug}/${id}`;
  }
  if (actions.includes('edit')) {
    routeActions.edit = (id: Id) => `/${slug}/${id}/edit`;
  }
  if (actions.includes('remove')) {
    routeActions.remove = (id: Id) => `/${slug}/${id}/delete`;
  }

  return {
    ...routeActions,
    ...customActions,
  };
};

export const SECTIONS: Sections = {
  [SECTION_COMMENTS]: {
    segment: kebabCase(SECTION_COMMENTS),
    routes: getSectionRoutes(SECTION_COMMENTS, [
      'new',
      'detail',
      'remove',
    ]),
  },
  [SECTION_NOTES]: {
    label: 'Notes',
    segment: kebabCase(SECTION_NOTES),
    routes: getSectionRoutes(SECTION_NOTES, [
      'new',
      'detail',
      'edit',
      'remove',
    ], {
      recent_sources: () => '/notes/recent-sources',
    }),
  },
  [SECTION_INDEXES]: {
    label: 'Indexes',
    segment: kebabCase(SECTION_INDEXES),
    routes: getSectionRoutes(SECTION_INDEXES, [], {
      kindOf: (kindOf: string) => `/indexes/${kindOf}`,
      new: (kindOf: string) => `/indexes/${kindOf}/new`,
      detail: (id: Id, kindOf?: string) => `/indexes/${kindOf}/${id}`,
      edit: (id: Id, kindOf?: string) => `/indexes/${kindOf}/${id}/edit`,
      remove: (id: Id, kindOf?: string) => `/indexes/${kindOf}/${id}/delete`,
    }),
  },
  [SECTION_LOOKUP]: {
    routes: {
      reference: () => '/lookup/reference',
    }
  },
  [SECTION_PEOPLE]: {
    label: 'People',
    segment: kebabCase(SECTION_PEOPLE),
    routes: getSectionRoutes(SECTION_PEOPLE, [
      'new',
      'detail',
      'edit',
      'remove',
    ], {
      tag: (id: Id) => `/people/${id}/tag`,
      notes: (id: Id) => `/people/${id}/notes`,
    }),
  },
  [SECTION_PERSON_TAGS]: {
    segment: kebabCase(SECTION_PERSON_TAGS),
    routes: getSectionRoutes(SECTION_PERSON_TAGS, [
      'new',
      'detail',
    ]),
  },
  [SECTION_SOURCE_TAGS]: {
    segment: kebabCase(SECTION_SOURCE_TAGS),
    routes: getSectionRoutes(SECTION_SOURCE_TAGS, [
      'new',
      'detail',
    ]),
  },
  [SECTION_SOURCES]: {
    label: 'Sources',
    segment: kebabCase(SECTION_SOURCES),
    routes: getSectionRoutes(SECTION_SOURCES, [
      'new',
      'detail',
      'edit',
      'remove',
    ], {
      kindOf: (kindOf: string) => `/sources/${kindOf}`,
      children: (id: Id) => `/sources/${id}/children`,
      notes: (id: Id) => `/sources/${id}/notes`,
    }),
  },
  [SECTION_NOTE_COMMENT_TASKS]: {
    segment: kebabCase(SECTION_NOTE_COMMENT_TASKS),
    routes: getSectionRoutes(SECTION_NOTE_COMMENT_TASKS, [
      'new',
      'detail',
      'remove',
    ]),
  },
  [SECTION_NOTE_TAGS]: {
    segment: kebabCase(SECTION_NOTE_TAGS),
    routes: getSectionRoutes(SECTION_NOTE_TAGS, [
      'new',
      'detail',
    ]),
  },
  [SECTION_NOTE_SOURCES]: {
    segment: kebabCase(SECTION_NOTE_SOURCES),
    routes: getSectionRoutes(SECTION_NOTE_SOURCES, [
      'detail',
    ]),
  },
  [SECTION_TAGS]: {
    label: 'Tags',
    segment: kebabCase(SECTION_TAGS),
    routes: getSectionRoutes(SECTION_TAGS, [
      'new',
      'detail',
      'edit',
      'remove',
    ], {
      notes: (id: Id) => `/tags/${id}/notes`,
      people: (id: Id) => `/tags/${id}/people`,
      sources: (id: Id) => `/tags/${id}/sources`,
    }),
  },
  [SECTION_TASKS]: {
    label: 'Tasks',
    segment: kebabCase(SECTION_TASKS),
    routes: getSectionRoutes(SECTION_TASKS, [
      'new',
      'detail',
      'edit',
      'remove',
    ]),
  },
  [SECTION_USERS]: {
    label: 'Users',
    segment: kebabCase(SECTION_USERS),
    routes: getSectionRoutes(SECTION_USERS, [
      'new',
      'detail',
      'edit',
      'remove',
    ], {
      records: (id: Id, kindOf?: string) =>
        kindOf
          ? `/users/${id}/records/${kindOf}`
          : `/users/${id}/records`,
    }),
  },
  [SECTION_SETTINGS]: {
    label: 'Settings',
    segment: kebabCase(SECTION_SETTINGS),
    routes: getSectionRoutes(SECTION_SETTINGS, [], {
      password: () => '/settings/password',
    }),
  }
};

export const getSection = (section: string) => SECTIONS[section];
export const getRoutes = (section: string) => getSection(section).routes;
export const redirectTo = (route: string) => window.location.assign(route);
