const KEYS = [
  'title',
  'subtitle',
  'original_title',
  'original_published_year',
  'catalog_number',
  'copyright_year',
  'publisher_city',
  'publisher_region',
  'publisher_country',
  'published_year',
  'publisher',
  'edition',
  'running_time',
  'format',
  'service',
  'url',
  'doi',
  'accessed_date',
  'is_collection',
  'vendor',
  'lookup_source',
  'lookup_key',
  'lookup_term'
] as string[];

export default function() {
  return KEYS;
}
