import React from 'react';
import { connect } from 'react-redux';

import EnterNewPassword from './enter-new-password';
import Login from './login';
import Messages from '../messages';
import RequestResetCode from './request-reset-code';
import SectionPrimary from '../section-primary';

import { getCurrent } from '../../selectors/env';

import type { RootState } from '../../stores/types';
import type { CurrentRoute } from '../../types';

interface Props {
  current: CurrentRoute;
}

const App = ({ current }: Props) => {
  const isPassword = current.section === 'login' && current.action === 'password';
  const hasToken = current.searchParams.has('token');
  const token = current.searchParams.get('token');

  return (
    <>
      <div className='section-layout'>
        <SectionPrimary>
          <header className='heading'>
            <h2>Welcome!</h2>
          </header>

          {isPassword ? (
            hasToken ? <EnterNewPassword token={token} /> : <RequestResetCode />
          ) : <Login />}
        </SectionPrimary>
      </div>
      <Messages />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  current: getCurrent(state),
});

export default connect(mapStateToProps)(App);
