import React from 'react';

import setClasses from '../lib/dom/set-classes';

interface Props {
  align?: string;
  content: string;
  isActive: boolean;
}

const Tooltip = ({ align = 'center', content, isActive }: Props) => {
  const classes = setClasses('tooltip', {
    'tooltip-left': align === 'left',
    'tooltip-right': align === 'right',
    active: isActive,
    inactive: !isActive
  });

  return (
    <div className={classes}>
      <div className='tooltip-content' dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default Tooltip;
