const KEYS = [
  'title',
  'abbreviation',
  'language',
  'includes_old_testament',
  'includes_new_testament',
  'includes_deuterocanonical',
  'partitioning',
  'entry_method'
] as string[];

export default function() {
  return KEYS;
}
