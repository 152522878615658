import { SECTION_USERS } from '../constants.js';

import uniq from '../lib/array/uniq';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeAllEntities,
  mergeEntityValues,
  setIsAdding,
  setIsRequesting,
  setUniqueIds,
  toggleId,
} from '../lib/redux-tools';

import { deletePost, get, post, put } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes, redirectTo } from '../lib/route';

import toPost from '../adapters/user/to-post';
import toPut from '../adapters/user/to-put';

import { getIsNewId } from '../selectors/users';

const DEACTIVATE_USER = 'DEACTIVATE_USER';
const SET_IS_ADDING_USER = 'SET_IS_ADDING_USER';
const SET_USER_RECORDS = 'SET_USER_RECORDS';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const ACTIVATE_USERS = 'ACTIVATE_USERS';
export const BEGIN_USER_REQUEST = 'BEGIN_USER_REQUEST';
export const DELETE_USER = 'DELETE_USER';
export const END_USER_REQUEST = 'END_USER_REQUEST';
export const SET_USER = 'SET_USER';
export const SET_USERS = 'SET_USERS';
const SET_USERS_PAGINATION = 'SET_USERS_PAGINATION';

const routes = getRoutes(SECTION_USERS);

export const activate = (idOrIds) =>
  Array.isArray(idOrIds)
    ? { type: ACTIVATE_USERS, ids: idOrIds }
    : { type: ACTIVATE_USER, id: idOrIds };

export const deleteItem = (id) => ({
  type: DELETE_USER, id,
});

export const getRecordsForUser = (user) => dispatch =>
  get(routes.records(user.id))
    .then(result => {
      const { data } = result;
      const { records } = data;

      dispatch(setRecords(user.id, records));

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const deleteRecordsForUser = (id, kindOf) => dispatch =>
  deletePost(routes.records(id, kindOf))
    .then(result => {
      const { data } = result;
      const { records } = data;

      dispatch(setRecords(id, records));

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const removeExisting = id => dispatch =>
  deletePost(routes.remove(id))
    .then(result => {
      dispatch(deleteItem(id));

      setTimeout(() => {
        redirectTo(routes.base());
      }, 400);

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const beginRequest = () => ({
  type: BEGIN_USER_REQUEST,
});

export const endRequest = () => ({
  type: END_USER_REQUEST,
});

const saveExisting = (id, values) => dispatch =>
  put(routes.edit(id), toPut(id, values))
    .then(result => {
      const { data } = result;
      const { user } = data;

      dispatch(set(user));

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

const saveNew = (id, values) => dispatch =>
  post(routes.new(), toPost(id, values))
    .then(result => {
      const { data } = result;
      const { user } = data;

      setTimeout(() => {
        redirectTo(routes.detail(user.id));
      }, 400);

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const save = (id, values) => (dispatch, getState) => {
  const isNew = getIsNewId(getState(), id);
  const method = isNew ? saveNew : saveExisting;

  return dispatch(method(id, values));
};

export const set = (user, isNew = false) => ({
  type: SET_USER, user, isNew,
});

export const setAll = (users) => ({
  type: SET_USERS, users,
});

export const setIsAddingItem = (isAdding) => ({
  type: SET_IS_ADDING_USER, isAdding,
});

export const setPagination = (pagination) => ({
  type: SET_USERS_PAGINATION, pagination,
});

export const setRecords = (id, records) => ({
  type: SET_USER_RECORDS, id, records,
});

const additionalIdFields = [
  'activeIds',
  'editingIds',
  'newIds',
];

const initialState = getInitialState({
  isRequesting: false,
  isAdding: false,
  pagination: null,
}, additionalIdFields);

export default create({
  [BEGIN_USER_REQUEST]: state => setIsRequesting(state, true),
  [END_USER_REQUEST]: state => setIsRequesting(state, false),
  [SET_IS_ADDING_USER]: (state, { isAdding }) => setIsAdding(state, isAdding),

  [SET_USERS]: (state, { users }) => ({
    ...state,
    entities: mergeAllEntities(state, users),
    ids: setUniqueIds(state, users),
  }),

  [SET_USERS_PAGINATION]: (state, { pagination }) => ({
    ...state,
    pagination,
  }),

  [SET_USER]: (state, { user, isNew }) => ({
    ...state,
    entities: mergeEntityValues(state, user),
    ids: uniq([ ...state.ids, user.id ]),
    editingIds: isNew
      ? uniq([ ...state.editingIds, user.id ])
      : state.editingIds,
    newIds: isNew
      ? uniq([ ...state.newIds, user.id ])
      : state.newIds,
  }),

  [SET_USER_RECORDS]: (state, { id, records }) => ({
    ...state,
    entities: {
      ...state.entities,
      [id]: {
        ...state.entities[id],
        records,
      }
    }
  }),

  [ACTIVATE_USER]: (state, { id }) => toggleId(state, id, 'activeIds', true),
  [ACTIVATE_USERS]: (state, { ids }) => toggleId(state, ids, 'activeIds', true),
  [DEACTIVATE_USER]: (state, { id }) => toggleId(state, id, 'activeIds', false),
  [DELETE_USER]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
}, initialState);
