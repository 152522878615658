import { combineReducers } from 'redux';

import attributions from './attributions';
import citations from './citations';
import comments from './comments';
import env from './env';
import indexes from './indexes';
import lookup from './lookup';
import messages from './messages';
import noteCommentTasks from './note-comment-tasks';
import notes from './notes';
import notesRecentSources from './notes-recent-sources';
import noteTags from './note-tags';
import people from './people';
import personTags from './person-tags';
import search from './search';
import sources from './sources';
import sourceTags from './source-tags';
import tags from './tags';
import tasks from './tasks';
import ui from './ui';
import users from './users';

const reducers = {
  attributions,
  citations,
  comments,
  env,
  indexes,
  lookup,
  messages,
  noteCommentTasks,
  notes,
  notesRecentSources,
  noteTags,
  people,
  personTags,
  search,
  sources,
  sourceTags,
  tags,
  tasks,
  ui,
  users
};

export default combineReducers(reducers);
