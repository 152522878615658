import { MEDIUMS } from '../constants.js';

import checkOpenLibraryStatus from '../actions/api/open-library/check-status';
import { setOpenLibraryStatus } from '../reducers/lookup';

export default function(store, action) {
  const { dispatch, getState } = store;

  const { modal } = getState().ui;
  const { values = {} } = action;

  if (modal.active === 'lookup') {
    if (values?.medium === MEDIUMS.book.model) {
      checkOpenLibraryStatus().then(isActive => {
        dispatch(setOpenLibraryStatus(isActive));
      });
    }
  }
}
