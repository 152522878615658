const KEYS = [
  'medium',
  'format',
  'title',
  'subtitle',
  'url',
  'doi',
  'is_partitioned',
  'partitioning',
  'reference',
  'published_date',
  'accessed_date'
] as string[];

export default function() {
  return KEYS;
}
