import { SECTION_PEOPLE } from '../constants.js';

import uniq from '../lib/array/uniq';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeAllEntities,
  mergeEntityValues,
  setIsRequesting,
  setUniqueIds,
  toggleId,
  updateItem,
} from '../lib/redux-tools';
import { deletePost, put } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes, redirectTo } from '../lib/route';

import toPut from '../adapters/person/to-put';

export const ACTIVATE_PEOPLE = 'ACTIVATE_PEOPLE';
export const ACTIVATE_PERSON = 'ACTIVATE_PERSON';
export const BEGIN_PERSON_REQUEST = 'BEGIN_PERSON_REQUEST';
export const DEACTIVATE_PERSON = 'DEACTIVATE_PERSON';
export const DELETE_PERSON = 'DELETE_PERSON';
export const END_PERSON_REQUEST = 'END_PERSON_REQUEST';
export const SET_PEOPLE = 'SET_PEOPLE';
const SET_PEOPLE_PAGINAGION = 'SET_PEOPLE_PAGINAGION';
export const SET_PERSON = 'SET_PERSON';
export const UPDATE_PERSON = 'UPDATE_PERSON';
export const SET_IS_ADDING_TAG_TO_PERSON = 'SET_IS_ADDING_TAG_TO_PERSON';
export const SET_IS_EDITING_TAGS_ON_PERSON = 'SET_IS_EDITING_TAGS_ON_PERSON';

const routes = getRoutes(SECTION_PEOPLE);

export const activate = (idOrIds) =>
  Array.isArray(idOrIds)
    ? { type: ACTIVATE_PEOPLE, ids: idOrIds }
    : { type: ACTIVATE_PERSON, id: idOrIds };

export const deactivate = (id) => ({
  type: DEACTIVATE_PERSON, id,
});

export const deleteItem = (id) => ({
  type: DELETE_PERSON, id,
});

export const removeExisting = id => dispatch =>
  deletePost(routes.remove(id))
    .then(result => {
      dispatch(deleteItem(id));

      setTimeout(() => {
        redirectTo(routes.base());
      }, 400);

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const beginRequest = () => ({
  type: BEGIN_PERSON_REQUEST,
});

export const endRequest = () => ({
  type: END_PERSON_REQUEST,
});

export const saveExisting = (id, values) => dispatch =>
  put(routes.edit(id), toPut(id, values))
    .then(result => {
      const { data } = result;
      const { person } = data;

      if (person.record) {
        dispatch(set(person.record));
      }

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const set = (person) => ({
  type: SET_PERSON, person,
});

export const setAll = (people) => ({
  type: SET_PEOPLE, people,
});

export const setPagination = (pagination) => ({
  type: SET_PEOPLE_PAGINAGION, pagination,
});

export const tag = id => dispatch =>
  put(routes.tag(id))
    .then(result => {
      const { data } = result;
      const { person } = data;

      dispatch(update(person.record));

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const update = (values) => ({
  type: UPDATE_PERSON, values,
});

const additionalIdFields = [
  'activeIds',
  'isAddingTagToIds',
  'isEditingTagsOnIds',
];

const initialState = getInitialState({
  isRequesting: false,
  pagination: null,
}, additionalIdFields);

export default create({
  [BEGIN_PERSON_REQUEST]: state => setIsRequesting(state, true),
  [END_PERSON_REQUEST]: state => setIsRequesting(state, false),

  [SET_PEOPLE]: (state, { people }) => ({
    ...state,
    entities: mergeAllEntities(state, people),
    ids: setUniqueIds(state, people),
  }),

  [SET_PEOPLE_PAGINAGION]: (state, { pagination }) => ({
    ...state,
    pagination,
  }),

  [SET_PERSON]: (state, { person }) => ({
    ...state,
    entities: mergeEntityValues(state, person),
    ids: uniq([ ...state.ids, person.id ])
  }),

  [UPDATE_PERSON]: (state, { values }) => updateItem(state, values),
  [ACTIVATE_PERSON]: (state, { id }) => toggleId(state, id, 'activeIds', true),
  [ACTIVATE_PEOPLE]: (state, { ids }) => toggleId(state, ids, 'activeIds', true),
  [DEACTIVATE_PERSON]: (state, { id }) => toggleId(state, id, 'activeIds', false),
  [DELETE_PERSON]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
  [SET_IS_ADDING_TAG_TO_PERSON]: (state, { id, isAddingTag }) => toggleId(state, id, 'isAddingTagToIds', isAddingTag),
  [SET_IS_EDITING_TAGS_ON_PERSON]: (state, { id, isEditingTags }) => toggleId(state, id, 'isEditingTagsOnIds', isEditingTags),
}, initialState);
