import type { RoleOption, Source } from '../../types';

const getAttributionsFromSource = (source: Source) => {
  if (!source.attributions) return false;

  const rolesInSource = Object.values(source.medium.roles)
    .map((role: RoleOption) => role.value);

  if (!rolesInSource.length) return false;

  return Object.values(source.attributions)
    .filter(attribution => rolesInSource.includes(attribution.role))
    .slice() // to avoid mutating the original array
    .sort((a, b) => rolesInSource.indexOf(a.role) - rolesInSource.indexOf(b.role))
    .map(attribution => {
      const { person, ...rest } = attribution;

      return {
        attribution: {
          ...rest,
          person_id: person.id,
        },
        person,
      };
    });
};

export default getAttributionsFromSource;
