import { WORKS, PERIODICALS, SELECTIONS } from '../../constants.js';

import { snakeCase } from '../../lib/text/case';

import bibleKeys from './keysets/bible';
import bookKeys from './keysets/book';
import filmKeys from './keysets/film';
import workKeys from './keysets/work';
import issueKeys from './keysets/issue';
import periodicalKeys from './keysets/periodical';
import personalCommunicationKeys from './keysets/personal-communication';
import selectionKeys from './keysets/selection';

const keySets = {
  bible: bibleKeys,
  book: bookKeys,
  film: filmKeys,
  ...WORKS.reduce((mediumObject, medium) => ({
    ...mediumObject,
    [medium]: workKeys
  }), {}),
  issue: issueKeys,
  periodical: periodicalKeys,
  ...PERIODICALS.reduce((mediumObject, medium) => ({
    ...mediumObject,
    [medium]: periodicalKeys
  }), {}),
  ...SELECTIONS.reduce((mediumObject, medium) => ({
    ...mediumObject,
    [medium]: selectionKeys
  }), {}),
  personal_communication: personalCommunicationKeys
};

export const getKeysFor = medium => {
  const key = snakeCase(medium);

  return keySets[key]();
};

export default getKeysFor;
