import React, { useState } from 'react';

import Tooltip from './tooltip';

interface Props {
  align?: string;
  content: string;
}

const InfoBug = ({ align = 'center', content }: Props) => {
  const [isActive, setIsActive] = useState(false);

  const handlers = {
    onMouseEnter: (e: React.MouseEvent<Element, MouseEvent>) => {
      e.preventDefault();
      setIsActive(true);
    },
    onMouseLeave: (e: React.MouseEvent<Element, MouseEvent>) => {
      e.preventDefault();
      setIsActive(false);
    },
    onTouchStart: (e: React.TouchEvent<Element>) => {
      e.preventDefault();
      setIsActive(true);
    },
    onTouchEnd: (e: React.TouchEvent<Element>) => {
      e.preventDefault();
      setIsActive(false);
    },
    onTouchCancel: (e: React.TouchEvent<Element>) => {
      e.preventDefault();
      setIsActive(false);
    },
  };

  return (
    <div className='info-bug-item'>
      <div className='info-bug' {...handlers}>i</div>
      <Tooltip content={content} align={align} isActive={isActive} />
    </div>
  );
};

export default InfoBug;
