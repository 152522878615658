import { PATTERNS } from '../constants.js';

const parsePathWithPattern = (pathname, pattern) => pathname.replace(/\/$/, '').match(pattern);

const getSegments = (pathname, overrides) => {
  const parsedPath = parsePathWithPattern(pathname, PATTERNS.pathname);

  if (parsedPath) {
    const [route, section, kindOf, id, action] = parsedPath;

    const values = {
      route,
      section,
      kindOf,
      id: id === 'new'
        ? id
        : id
          ? Number(id)
          : undefined,
      action: action
        ? action
        : id === 'new'
          ? id
          : id
            ? 'show'
            : 'index',
    };

    if (section in overrides) {
      if (!action && overrides[section].actions.includes(kindOf)) {
        values.action = kindOf;
        values.kindOf = undefined;
      }
    }

    return values;
  } else {
    return {
      route: '/',
      section: undefined,
      kindOf: undefined,
      id: undefined,
      action: undefined,
    };
  }
};

export const getCurrentRoute = (overrides = {}) => {
  const url = new URL(window.location);
  const pathname = url.pathname;
  const hash = url.hash;
  const searchParams = url.searchParams;

  const { route, section, kindOf, id, action } = getSegments(pathname, overrides);

  return {
    route,
    section,
    hash: hash.startsWith('#') ? hash.replace('#', '') : undefined,
    kindOf,
    id,
    page: searchParams.has('page') ? Number(searchParams.get('page')) : 1,
    parent_id: searchParams.has('parent_id') ? Number(searchParams.get('parent_id')) : undefined,
    action,
    searchParams,
  };
}
