import React from 'react';

import Actions from '../item-actions';
import Action from './action';
import Checkmark from '../images/ui/checkmark';
import FieldItem from '../form/field-item';

interface Props {
  token: string;
}

const EnterNewPassword = ({ token }: Props) => {
  return (
    <form className='item-details item-panel' name='login' method='post' action={`/login/password?token=${token}`}>
      <fieldset className='item-header-and-meta'>
        <div className='item-header'>
          <h3>Enter a new password.</h3>
        </div>

        <div className='item-meta'>
          <Actions size='large'>
            <Action
              actionRequired={true}
              isDisabled={false}
              title='Sign in'
              type='save'
            >
              <Checkmark />
            </Action>
          </Actions>
        </div>
      </fieldset>

      <fieldset className='item-description'>
        <input
          type='hidden'
          name='login[token]'
          defaultValue={token}
        />

        <FieldItem name='login-new-password' label='Password'>
          <input
            type='password'
            name='login[new_password]'
            id='login-new-password'
            autoFocus
          />
        </FieldItem>

        <FieldItem name='login-new-password-retype' label='Retype Password'>
          <input
            type='password'
            name='login[new_password_again]'
            id='login-new-password-retype'
          />
        </FieldItem>
      </fieldset>
    </form>
  );
};

export default EnterNewPassword;
