import {
  remove,
  removeFromRemoveQueue,
} from '../reducers/messages';

export default function(store, action) {
  const { dispatch, getState } = store;

  setTimeout(() => {
    if (getState().messages.ids.includes(action.id)) {
      dispatch(removeFromRemoveQueue(action.id));
      dispatch(remove(action.id));
    }
  }, 250);
}
