import { createStore, applyMiddleware, compose } from 'redux'
import { thunk } from 'redux-thunk';

import rootReducer from '../reducers/index';
import setHandlers from '../middleware/set-handlers';

export const isDevelopment = process.env.NODE_ENV === 'development';

const middlewareEnhancer = applyMiddleware(thunk, setHandlers);
const composeEnhancers = (isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

let store;

export default function getStore(reducer = null) {
  if (!store) {
    store = createStore(reducer || rootReducer, {}, composeEnhancers(middlewareEnhancer));
  }

  return store;
}
