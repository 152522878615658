import { v4 as UUID } from 'uuid';

import uniq from '../lib/array/uniq';
import without from '../lib/array/without';
import {
  create,
  deleteEntityAtId,
} from '../lib/redux-tools';

export const ACTIVATE_MESSAGE = 'ACTIVATE_MESSAGE';
export const ADD_MESSAGE_TO_ACTIVATE_QUEUE = 'ADD_MESSAGE_TO_ACTIVATE_QUEUE';
export const ADD_MESSAGE_TO_REMOVE_QUEUE = 'ADD_MESSAGE_TO_REMOVE_QUEUE';
export const DEACTIVATE_MESSAGE = 'DEACTIVATE_MESSAGE';
const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
const REMOVE_MESSAGE_FROM_ACTIVATE_QUEUE = 'REMOVE_MESSAGE_FROM_ACTIVATE_QUEUE';
const REMOVE_MESSAGE_FROM_REMOVE_QUEUE = 'REMOVE_MESSAGE_FROM_REMOVE_QUEUE';
export const SET_MESSAGE = 'SET_MESSAGE';

export const activate = (id) => ({
  type: ACTIVATE_MESSAGE, id,
});
export const deactivate = (id) => ({
  type: DEACTIVATE_MESSAGE, id,
});
export const addToActivateQueue = id => ({
  type: ADD_MESSAGE_TO_ACTIVATE_QUEUE, id,
});
export const removeFromActivateQueue = id => ({
  type: REMOVE_MESSAGE_FROM_ACTIVATE_QUEUE, id,
});
export const addToRemoveQueue = id => ({
  type: ADD_MESSAGE_TO_REMOVE_QUEUE, id
});
export const removeFromRemoveQueue = id => ({
  type: REMOVE_MESSAGE_FROM_REMOVE_QUEUE, id,
});
export const remove = (id) => ({
  type: REMOVE_MESSAGE, id,
});
export const setAndActivate = (message) => (dispatch) => {
  const newMessage = set(message);
  const id = newMessage.message.id;

  dispatch(newMessage);
  dispatch(addToActivateQueue(id));
};
export const set = (message) => ({
  type: SET_MESSAGE,
  message: {
    id: UUID(),
    isActive: false,
    ...message
  },
});

const initialState = {
  entities: {},
  ids: [],
  idsToActivate: [],
  idsToRemove: [],
};

export default create({
  [SET_MESSAGE]: (state, { message }) => ({
    ...state,
    entities: {
      ...state.entities,
      [message.id]: message,
    },
    ids: [...state.ids, message.id],
  }),

  [ADD_MESSAGE_TO_ACTIVATE_QUEUE]: (state, { id }) => ({
    ...state,
    idsToActivate: uniq([ ...state.idsToActivate, id, ]),
  }),

  [REMOVE_MESSAGE_FROM_ACTIVATE_QUEUE]: (state, { id }) => ({
    ...state,
    idsToActivate: without(state.idsToActivate, id),
  }),

  [ADD_MESSAGE_TO_REMOVE_QUEUE]: (state, { id }) => ({
    ...state,
    idsToRemove: uniq([ ...state.idsToRemove, id, ]),
  }),

  [REMOVE_MESSAGE_FROM_REMOVE_QUEUE]: (state, { id }) => ({
    ...state,
    idsToRemove: without(state.idsToRemove, id),
  }),

  [ACTIVATE_MESSAGE]: (state, { id }) => ({
    ...state,
    entities: {
      ...state.entities,
      [id]: {
        ...state.entities[id],
        isActive: true,
      },
    },
  }),

  [DEACTIVATE_MESSAGE]: (state, { id }) => ({
    ...state,
    entities: {
      ...state.entities,
      [id]: {
        ...state.entities[id],
        isActive: false,
      },
    },
  }),

  [REMOVE_MESSAGE]: (state, { id }) => deleteEntityAtId(state, id),
}, initialState);
