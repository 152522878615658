import { createSelector } from 'reselect'

import { FORM_NAME_STEM, LOOKUP, NEW_SELECTION } from '../constants.js';

import { getCurrent } from './env';

export const getFormForSource = id => `${FORM_NAME_STEM}_${id}`;

const getSourcesState = state => state.sources;

const getIds = createSelector(getSourcesState, sources => sources.ids);
const getNewIds = createSelector(getSourcesState, sources => sources.newIds);
export const getById = createSelector(getSourcesState, sources => sources.entities);
export const getActiveIds = createSelector(getSourcesState, sources => sources.activeIds);
export const getCitations = createSelector(getSourcesState, sources => sources.citations);
export const getPartitionings = createSelector(getSourcesState, sources => sources.partitionings);

export const getIsAdding = createSelector(getSourcesState, sources => sources.isAdding);
export const getIsRequesting = createSelector(getSourcesState, sources => sources.isRequesting);
export const getIsAddingTagToIds = createSelector(getSourcesState, sources => sources.isAddingTagToIds);
export const getIsEditingTagsOnIds = createSelector(getSourcesState, sources => sources.isEditingTagsOnIds);
export const getPagination = createSelector(getSourcesState, sources => sources.pagination);

const getAll = createSelector(
  [getById, getIds], (entities, ids) => ids.map(id => entities[id])
);

const getExistingIds = createSelector(
  [getIds, getNewIds], (ids, newIds) => ids.filter(id => !newIds.includes(id))
);
export const getExisting = createSelector(
  [getById, getExistingIds], (entities, ids) => ids.map(id => entities[id])
);

export const getHasSources = createSelector(getAll, sources => Boolean(sources.length));

export const getSourceAtId = (state, id) => getById(state)[id] || {};
export const getHasSource = (state, id) => getIds(state).includes(id);
export const getSourceChildGroups = (state, id) => getSourceAtId(state, id)?.childGroups ?? [];

export const getIsActiveId = (state, id) => getActiveIds(state).includes(id);

const getFormats = createSelector(getSourcesState, sources => sources.formats);
export const getFormatsForCollection = (state, medium) => {
  const formats = getFormats(state);
  const hasFormats = Object.keys(formats).length > 0;
  const hasFormatsForMedium = medium in formats;

  return hasFormats && hasFormatsForMedium ? Object.values(formats[medium]) : [];
};

export const getKindsOf = createSelector(getSourcesState, sources => sources.kindsOf);

export const getModal = createSelector(getSourcesState, sources => sources.modal);
export const getModalContents = createSelector(getModal, modal => modal.contents);
export const getModalIsActive = createSelector(getModal, modal => modal.isActive);
const getModalKindOf = createSelector(getModal, modal => modal.kindOf);
const getEnvSourceId = createSelector(getCurrent, current => current?.id);
export const getModalSourceId = createSelector(getModalContents, contents => contents?.id);
export const getHasModalSource = createSelector(getModalSourceId, sourceId => sourceId !== null);

export const getIsLookupModal = createSelector(getModalKindOf, kindOf => kindOf === LOOKUP);
export const getIsNewSelectionModal = createSelector(getModalKindOf, kindOf => kindOf === NEW_SELECTION);

export const getModalSource = createSelector(
  getById,
  getModalSourceId,
  getEnvSourceId,
  (entities, sourceId, envId) => entities[sourceId || envId]
);

export const getInitialValues = createSelector(
  getModalSource, (source) => {
    const sourceValues = {
      id: source.id,
      locale: source.locale,
      medium: {
        id: source.medium.id,
        medium: source.medium.medium,
      },
      parent: {
        id: source.parent?.id,
      },
    };

    return {
      attributions: source.attributions || {},
      collection: source.medium?.collection,
      mediums: source.medium ? {
        [source.medium?.id]: source.medium,
      } : undefined,
      id: source.medium?.id, // todo: can we shed this?
      source: sourceValues,
    };
  }
);
