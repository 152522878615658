import { SECTION_TAGS } from '../constants.js';

import uniq from '../lib/array/uniq';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeAllEntities,
  mergeEntityValues,
  setIsRequesting,
  setUniqueIds,
  updateItem,
} from '../lib/redux-tools';
import { deletePost, get, post, put } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes, redirectTo } from '../lib/route';

import adaptSource from '../adapters/source/adapt';
import { toPost } from '../adapters/tags';
import toPut from '../adapters/tag/to-put';

import { INIT_ALL } from './env';
import {
  activate as activateNote,
  beginRequest as beginNotesRequest,
  deactivate as deactivateNote,
  endRequest as endNoteRequest,
  processData as processNote,
  setPagination as setNotesPagination,
} from './notes';
import {
  activate as activatePerson,
  beginRequest as beginPeopleRequest,
  deactivate as deactivatePerson,
  endRequest as endPeopleRequest,
  set as setPerson,
  // processData as processPerson,
  setPagination as setPeoplePagination,
} from './people';
import {
  activate as activateSources,
  beginRequest as beginSourcesRequest,
  deactivate as deactivateSource,
  endRequest as endSourceRequest,
  setAll as setAllSources,
  setKindsOf as setSourceKindsOf,
  setPagination as setSourcesPagination,
} from './sources';

import { getActiveIds as getActiveNoteIds } from '../selectors/notes';
import { getActiveIds as getActivePersonIds } from '../selectors/people';
import { getActiveIds as getActiveSourceIds } from '../selectors/sources';

export const BEGIN_TAG_REQUEST = 'BEGIN_TAG_REQUEST';
export const DELETE_TAG = 'DELETE_TAG';
export const END_TAG_REQUEST = 'END_TAG_REQUEST';
export const SET_TAG = 'SET_TAG';
export const SET_TAG_COLOR_OPTIONS = 'SET_TAG_COLOR_OPTIONS';
export const SET_TAGS = 'SET_TAGS';
export const UPDATE_TAG = 'UPDATE_TAG';

const routes = getRoutes(SECTION_TAGS);

export const deleteItem = (id) => ({
  type: DELETE_TAG, id,
});

export const getNotesForTag = (tag, page) => (dispatch, getState) => {
  const endpoint = routes.notes(tag.id);

  dispatch(beginNotesRequest());

  get(page ? `${endpoint}?page=${page}` : endpoint)
    .then(result => {
      const state = getState();
      const { data } = result;
      const { notes, source } = data;

      if (notes) {
        if (notes.records.length) {
          const ids = notes.records.map(note => note.id);
          const activeIds = getActiveNoteIds(state);

          notes.records.map(note => {
            dispatch(processNote(note, source));
          });

          if (notes.pagination) {
            dispatch(setNotesPagination(notes.pagination));
          }

          dispatch(update({ id: tag.id, notes: ids }));
          dispatch(deactivateNote(activeIds));
          dispatch(activateNote(ids));
        }
      }

      dispatch(endNoteRequest());

      return data;
    });
};

export const getPeopleForTag = (tag, page) => (dispatch, getState) => {
  const endpoint = routes.people(tag.id);

  dispatch(beginPeopleRequest());

  get(page ? `${endpoint}?page=${page}` : endpoint)
    .then(result => {
      const state = getState();
      const { data } = result;
      const { people } = data;

      if (people) {
        if (people.records.length) {
          const ids = people.records.map(person => person.id);
          const activeIds = getActivePersonIds(state);

          people.records.map(person => {
            dispatch(setPerson(person));
          });

          if (people.pagination) {
            dispatch(setPeoplePagination(people.pagination));
          }

          dispatch(update({ id: tag.id, people: ids }));
          dispatch(deactivatePerson(activeIds));
          dispatch(activatePerson(ids));
        }
      }

      dispatch(endPeopleRequest());

      return data;
    });
};

export const getSourcesForTag = (tag, page) => (dispatch, getState) => {
  const endpoint = routes.sources(tag.id);

  dispatch(beginSourcesRequest());

  get(page ? `${endpoint}?page=${page}` : endpoint)
    .then(result => {
      const state = getState();
      const { data } = result;
      const { sources, kinds_of } = data;

      if (sources) {
        if (sources.records.length) {
          const ids = sources.records.map(note => note.id);
          const activeIds = getActiveSourceIds(state);

          dispatch(setAllSources(sources.records.map(adaptSource)));
          dispatch(activateSources(sources.records.map((source) => source.id)));

          dispatch(update({ id: tag.id, sources: ids }));
          dispatch(deactivateSource(activeIds));
          dispatch(activateSources(ids));
        }

        if (sources.pagination) {
          dispatch(setSourcesPagination(sources.pagination));
        }

        if (kinds_of) {
          dispatch(setSourceKindsOf(kinds_of));
        }
      }

      dispatch(endSourceRequest());

      return data;
    });
};

export const removeExisting = id => dispatch =>
  deletePost(routes.remove(id))
    .then(result => {
      setTimeout(() => {
        redirectTo(routes.base());
      }, 400);

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const beginRequest = () => ({
  type: BEGIN_TAG_REQUEST,
});

export const endRequest = () => ({
  type: END_TAG_REQUEST,
});

export const saveExisting = (id, values) => dispatch =>
  put(routes.edit(id), toPut(id, values))
    .then(result => {
      const { data } = result;
      const tag = data.tag.record;

      dispatch(update(tag));

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const saveNewWithValue = value => dispatch =>
  post(routes.new(), toPost(value))
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const setColorOptions = (colorOptions) => ({
  type: SET_TAG_COLOR_OPTIONS, colorOptions,
});

export const set = (tag, isNew = false) => ({
  type: SET_TAG, tag, isNew,
});

export const setAll = (tags) => ({
  type: SET_TAGS, tags,
});

export const update = (values) => ({
  type: UPDATE_TAG, values,
});

const additionalIdFields = [
  // 'activeIds',
  'newIds',
];

const initialState = getInitialState({
  colorOptions: [],
  isRequesting: false,
}, additionalIdFields);

export default create({
  [INIT_ALL]: () => initialState,

  [BEGIN_TAG_REQUEST]: state => setIsRequesting(state, true),
  [END_TAG_REQUEST]: state => setIsRequesting(state, false),

  [SET_TAG_COLOR_OPTIONS]: (state, { colorOptions }) => ({
    ...state,
    colorOptions
  }),

  [SET_TAGS]: (state, { tags }) => ({
    ...state,
    entities: mergeAllEntities(state, tags),
    ids: setUniqueIds(state, tags),
  }),

  [SET_TAG]: (state, { tag, isNew }) => ({
    ...state,
    entities: mergeEntityValues(state, tag),
    ids: uniq([ ...state.ids, tag.id ]),
    newIds: isNew
      ? uniq([ ...state.newIds, tag.id ])
      : state.newIds,
  }),

  [UPDATE_TAG]: (state, { values }) => updateItem(state, values),
  [DELETE_TAG]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
}, initialState);
