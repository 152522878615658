import React, { ReactNode } from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import Message from './message';
import { getMessages } from '../selectors/messages';

import { Message as MessageType } from '../types';
import type { RootState } from '../stores/types';

const target = document.getElementById('flash-root');

interface Props {
  messages: MessageType[];
}

const Messages = ({ messages }: Props) => {
  return (
    <div className='flash'>
      {messages.length > 0 && (
        <ul className='flash-messages'>
          {messages.map((message) => <Message key={message.id} {...message} />)}
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  messages: getMessages(state),
});

const ConnectedMessages = connect(mapStateToProps)(Messages);

const MessagePortal = () => ReactDOM.createPortal(
  <ConnectedMessages />,
  target
) as ReactNode;

export default MessagePortal;
