import { createSelector } from 'reselect';

const getEnvState = state => state.env;

export const getAction = createSelector(getEnvState, env => env.action);
export const getCurrent = createSelector(getEnvState, env => env.current);
const getFeatures = createSelector(getEnvState, env => env.features || []);
export const getLocales = createSelector(getEnvState, env => env.locales);
export const getSection = createSelector(getEnvState, env => env.section);

export const getActionIsEditNewOrDelete = createSelector(getAction, action => (
  action.isDelete ||
  action.isEdit ||
  action.isNew
));

export const getLocale = (state, code) => getLocales(state).find(locale => locale.code === code);

export const getHasUser = createSelector(getCurrent, current => Object.keys(current.user).length > 0);
export const getCurrentId = createSelector(getCurrent, current => current.id);
export const getCurrentKindOf = createSelector(getCurrent, current => current.kindOf);
export const getCurrentPage = createSelector(getCurrent, current => current.page);
export const getCurrentUser = createSelector(getCurrent, current => current.user);
export const getUserDocstyle = createSelector(getCurrentUser, user => user.docstyle);
export const getUserLocale = createSelector(getCurrentUser, user => user.locale);

export const getHasFeature = (state, key) => getFeatures(state).includes(key);
