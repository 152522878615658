import { createSelector } from 'reselect'

import { asc } from '../lib/sorting/title';

const getTagsState = state => state.tags;

const getById = createSelector(getTagsState, tags => tags.entities);
const getIds = createSelector(getTagsState, tags => tags.ids);
const getNewIds = createSelector(getTagsState, tags => tags.newIds);

export const getIsRequesting = createSelector(getTagsState, tags => tags.isRequesting);

export const getHasTags = createSelector(getIds, ids => ids.length > 0);
const getSavedIds = createSelector([getIds, getNewIds], (ids, newIds) => ids.filter(id => !newIds.includes(id)));

export const getAvailableTags = (state, excludeIds) => {
  const hasTags = getHasTags(state);

  if (hasTags) {
    const savedIds = getSavedIds(state);

    return savedIds
      .filter(id => !excludeIds.includes(id))
      .map(id => getById(state)[id]);
  } else {
    return [];
  }
};

export const getTagsMatchingValue = (state, value, unavailableIds = []) => {
  if (!value.length) return [];

  const availableTags = getAvailableTags(state, unavailableIds);
  const matches = availableTags.filter(tag => tag.title.match(value));

  return matches;
};

export const getMatchingTag = (state, value) =>
  Object.values(getById(state)).find(tag => tag.title === value);

export const getTags = createSelector(
  [getById, getIds], (entities, ids) => ids.map(id => entities[id]).sort(asc)
);

export const getTagAtId = (state, id) => getById(state)[id] || {};
export const getHasTag = (state, id) => getIds(state).includes(id);
