import { SECTION_SOURCE_TAGS } from '../constants.js';

import uniq from '../lib/array/uniq';
import withoutProp from '../lib/object/without';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeAllEntities,
  mergeEntityValues,
  setUniqueIds,
  toggleId,
} from '../lib/redux-tools';
import { deletePost, post } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes } from '../lib/route';

import { setWarning } from '../actions/messages';

import buildNewSourceTag from '../adapters/source-tag/build-new';
import toPost from '../adapters/source-tag/to-post';
import { buildNewTag } from '../adapters/tags';

import {
  SET_IS_ADDING_TAG_TO_SOURCE,
  SET_IS_EDITING_TAGS_ON_SOURCE,
} from './sources';
import {
  deleteItem as deleteTag,
  saveNewWithValue as saveNewTagWithValue,
  set as setTag,
} from './tags';

import { getIsNewId, getValues } from '../selectors/source-tags';
import { getMatchingTag } from '../selectors/tags';
import { getTagIsSetOnSource } from '../selectors/source-tags';

export const ACTIVATE_SOURCE_TAG = 'ACTIVATE_SOURCE_TAG';
const ACTIVATE_SOURCE_TAGS = 'ACTIVATE_SOURCE_TAGS';
export const DEACTIVATE_SOURCE_TAG = 'DEACTIVATE_SOURCE_TAG';
export const DELETE_SOURCE_TAG = 'DELETE_SOURCE_TAG';
export const SET_SOURCE_TAG = 'SET_SOURCE_TAG';
export const SET_SOURCE_TAGS = 'SET_SOURCE_TAGS';

const routes = getRoutes(SECTION_SOURCE_TAGS);

export const activate = (idOrIds) =>
  Array.isArray(idOrIds)
    ? { type: ACTIVATE_SOURCE_TAGS, ids: idOrIds }
    : { type: ACTIVATE_SOURCE_TAG, id: idOrIds };

export const beginAddingToSource = (sourceId) =>
  setIsAddingToSource(sourceId, true);

export const beginEditingOnSource = (sourceId, ids) =>
  setIsEditingOnSource(sourceId, true, ids);

export const createNew = sourceId => dispatch => {
  const tag = buildNewTag();
  const sourceTag = buildNewSourceTag(sourceId, tag.id);

  Promise.resolve(
    dispatch(setTag(tag, true)),
    dispatch(set(sourceTag, true))
  ).then(() => {
    dispatch(beginAddingToSource(sourceId));
  }).then(() => {
    dispatch(activate(sourceTag.id));
  });
};

export const deactivate = (id) => ({
  type: DEACTIVATE_SOURCE_TAG, id,
});

export const deleteItem = (id) => ({
  type: DELETE_SOURCE_TAG, id,
});

export const endAddingToSource = (sourceId) =>
  setIsAddingToSource(sourceId, false);

export const endEditingOnSource = (sourceId, ids) =>
  setIsEditingOnSource(sourceId, false, ids);

export const warnIsDuplicate = (value) =>
  setWarning(`You’ve already tagged this source with “${value}”`);

export const removeUnsaved = (itemId, id, tagId) => dispatch => {
  Promise.resolve(
    dispatch(deactivate(id))
  ).then(() => {
    setTimeout(() => {
      dispatch(endAddingToSource(itemId));
      dispatch(deleteItem(id));
      dispatch(deleteTag(tagId));
    }, 400);
  });
};

export const remove = id => dispatch => {
  // todo: nope
  Promise.resolve(
    dispatch(deactivate(id))
  ).then(() => {
    deletePost(routes.detail(id))
      .then(() => {
        dispatch(deleteItem(id));
      })
      .catch(() => {
        dispatch(setWarning());
      });
  });
};

export const saveNew = (_id, values) => dispatch =>
  post(routes.new(), toPost(_id, values))
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const saveNewWithId = (_id, tagId, values) => dispatch => {
  let newValues = values;

  newValues = withoutProp(newValues, 'id');
  newValues = withoutProp(newValues, 'title');
  newValues = { ...newValues, tag_id: tagId };

  return dispatch(saveNew(_id, newValues))
    .then(result => {
      const { data } = result;
      const { source_tag } = data;

      dispatch(set(source_tag.record));
      dispatch(activate(source_tag.record.id));

      return result;
    });
};

const saveNewWithTitle = (title, values) => dispatch =>
  dispatch(saveNewTagWithValue(title))
    .then(result => {
      const { data } = result;
      const { tag } = data;

      dispatch(setTag(tag.record));

      dispatch(saveNew(values.id, {
        ...values,
        tag_id: tag.record.id,
      }))
        .then(result => {
          const { data } = result;
          const { source_tag } = data;

          dispatch(set(source_tag.record));
          dispatch(activate(source_tag.record.id));
        });

      return result;
    });

export const save = (_id, formValues) => (dispatch, getState) => {
  const state = getState();

  const isNewSourceTag = getIsNewId(state, _id);

  if (isNewSourceTag) {
    const values = getValues(state, _id);
    const { title } = formValues;
    const matchingTag = getMatchingTag(state, title);

    if (matchingTag) {
      const { source_id } = values;
      const tagIsSetOnSource = getTagIsSetOnSource(state, source_id, matchingTag.id);

      if (tagIsSetOnSource) {
        return dispatch(warnIsDuplicate(title));
      } else {
        return dispatch(saveNewWithId(_id, matchingTag.id, values));
      }
    } else {
      return dispatch(saveNewWithTitle(title, values));
    }
  } else {
    return dispatch({ type: "NOOP" });
  }
};

export const set = (sourceTag, isNew = false) => ({
  type: SET_SOURCE_TAG, sourceTag, isNew,
});

export const setAll = (sourceTags) => ({
  type: SET_SOURCE_TAGS, sourceTags,
});

export const setIsAddingToSource = (sourceId, isAddingTag) => ({
  type: SET_IS_ADDING_TAG_TO_SOURCE, id: sourceId, isAddingTag,
});

export const setIsEditingOnSource = (sourceId, isEditingTags, ids) => ({
  type: SET_IS_EDITING_TAGS_ON_SOURCE, id: sourceId, isEditingTags, ids,
});

const additionalIdFields = [
  'activeIds',
  'editingIds',
  'newIds',
];

const initialState = getInitialState({}, additionalIdFields);

export default create({
  [SET_SOURCE_TAGS]: (state, { sourceTags }) => ({
    ...state,
    entities: mergeAllEntities(state, sourceTags),
    ids: setUniqueIds(state, sourceTags),
  }),

  [SET_SOURCE_TAG]: (state, { sourceTag, isNew }) => ({
    ...state,
    entities: mergeEntityValues(state, sourceTag),
    ids: isNew
      ? state.ids
      : uniq([ ...state.ids, sourceTag.id ]),
    newIds: isNew
      ? uniq([ ...state.newIds, sourceTag.id ])
      : state.newIds,
  }),

  [SET_IS_EDITING_TAGS_ON_SOURCE]: (state, { ids, isEditingTags }) => toggleId(state, ids, 'editingIds', isEditingTags),
  [ACTIVATE_SOURCE_TAG]: (state, { id }) => toggleId(state, id, 'activeIds', true),
  [ACTIVATE_SOURCE_TAGS]: (state, { ids }) => toggleId(state, ids, 'activeIds', true),
  [DEACTIVATE_SOURCE_TAG]: (state, { id }) => toggleId(state, id, ['activeIds', 'editingIds'], false),
  [DELETE_SOURCE_TAG]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
}, initialState);
