import { createSelector } from 'reselect'

import createdDesc from '../lib/sorting/created-desc';

import toFormValues from '../adapters/note/to-form-values';
import getStringsForNullValues from '../lib/object/get-strings-for-null-values';

const getNotesState = state => state.notes;

const getIds = createSelector(getNotesState, notes => notes.ids);
export const getById = createSelector(getNotesState, notes => notes.entities);
export const getActiveIds = createSelector(getNotesState, notes => notes.activeIds);
const getEditingIds = createSelector(getNotesState, notes => notes.editingIds);
const getNewIds = createSelector(getNotesState, notes => notes.newIds);

export const getIsAdding = createSelector(getNotesState, notes => notes.isAdding);
export const getIsAddingCommentToIds = createSelector(getNotesState, notes => notes.isAddingCommentToIds);
export const getIsAddingTagToIds = createSelector(getNotesState, notes => notes.isAddingTagToIds);
export const getIsEditingTagsOnIds = createSelector(getNotesState, notes => notes.isEditingTagsOnIds);
export const getIsRequesting = createSelector(getNotesState, notes => notes.isRequesting);

export const getPagination = createSelector(getNotesState, notes => notes.pagination);

export const getNotes = createSelector(
  [getById, getIds], (entities, ids) => ids.map(id => entities[id])
);

export const getHasNotes = createSelector(getNotes, notes => Boolean(notes.length));

export const getNotesBySourceId = createSelector(
  [getById, getIds], (entities, ids) =>
    ids.reduce((bySourceId, id) => {
      const note = entities[id];
      const { source_id } = note;
      const notes = source_id in bySourceId
        ? {
            [source_id]: [ 
              ...bySourceId[source_id],
              note
            ].sort(createdDesc)
          }
        : {
            [source_id]: [ note ]
          };
      return {
        ...bySourceId,
        ...notes
      }
    },
    {})
);

export const getIsActiveId = (state, id) => getActiveIds(state).includes(id);
export const getIsEditingId = (state, id) => getEditingIds(state).includes(id);
export const getIsNewId = (state, id) => getNewIds(state).includes(id);

export const getNoteAtId = (state, id) => getById(state)[id] || {};
export const getHasNote = (state, id) => getIds(state).includes(id);
export const getInitialState = (state, id) => toFormValues(getStringsForNullValues(getNoteAtId(state, id)));

export const getValues = (state, id) => toFormValues(getNoteAtId(state, id));
