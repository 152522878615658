import { SECTION_NOTES } from '../constants.js';

import { create } from '../lib/redux-tools';
import { get } from '../lib/request';
import { getRoutes } from '../lib/route';

import {
  activate as activateSources,
  setAll as setSources,
} from './sources';

import adapt from '../adapters/source/adapt';

export const BEGIN_NOTES_RECENT_SOURCES_REQUEST = 'BEGIN_NOTES_RECENT_SOURCES_REQUEST';
export const END_NOTES_RECENT_SOURCES_REQUEST = 'END_NOTES_RECENT_SOURCES_REQUEST';
export const SET_NOTES_RECENT_SOURCES = 'SET_NOTES_RECENT_SOURCES';

const routes = getRoutes(SECTION_NOTES);

export const getAll = () => dispatch => {
  dispatch(beginRequest());
  get(routes.recent_sources())
    .then(result => {
      const { data } = result;
      const { recent_sources } = data;

      const sources = recent_sources.records.map(adapt);
      const sourceIds = sources.map(source => source.id);

      dispatch(setSources(sources));
      dispatch(activateSources(sourceIds));
      dispatch(setAll(sourceIds));

      dispatch(endRequest());

      return data;
    });
};

export const beginRequest = () => ({
  type: BEGIN_NOTES_RECENT_SOURCES_REQUEST,
});

export const endRequest = () => ({
  type: END_NOTES_RECENT_SOURCES_REQUEST,
});

export const setAll = (ids) => ({
  type: SET_NOTES_RECENT_SOURCES, ids,
});

const initialState = {
  ids: [],
  isReady: false,
  isRequesting: false
};

export default create({
  [BEGIN_NOTES_RECENT_SOURCES_REQUEST]: state => ({
    ...state,
    isRequesting: true
  }),

  [END_NOTES_RECENT_SOURCES_REQUEST]: state => ({
    ...state,
    isRequesting: false
  }),

  [SET_NOTES_RECENT_SOURCES]: (state, { ids }) => ({
    ...state,
    ids,
    isReady: true
  })
}, initialState);
