import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  name?: string;
}

export const FieldLabel = ({ name, children }: Props) => (
  <label {...{
    className: 'item-field-label',
    htmlFor: name,
  }}>
    {children}
  </label>
);

export default FieldLabel;
