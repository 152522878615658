import { SECTION_NOTES } from '../constants.js';

import uniq from '../lib/array/uniq';
import {
  setFlashSuccess,
  setFlashWarning,
} from '../lib/flash';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeEntityValues,
  setIsRequesting,
  toggleId,
} from '../lib/redux-tools';
import { deletePost, get, post, put } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes, redirectTo } from '../lib/route';

import adapt from '../adapters/note/adapt';
import buildNew from '../adapters/note/build-new';
import toFormValues from '../adapters/note/to-form-values';
import toPost from '../adapters/note/to-post';
import toPut from '../adapters/note/to-put';

import { getIsNewId, getValues } from '../selectors/notes';

const ACTIVATE_NOTES = 'ACTIVATE_NOTES';
const BEGIN_NOTES_REQUEST = 'BEGIN_NOTES_REQUEST';
const DELETE_NOTE = 'DELETE_NOTE';
const EDIT_NOTE = 'EDIT_NOTE';
const END_NOTES_REQUEST = 'END_NOTES_REQUEST';
const EXIT_EDIT_NOTE = 'EXIT_EDIT_NOTE';
const SET_IS_ADDING_NOTE = 'SET_IS_ADDING_NOTE';
export const SET_NOTE = 'SET_NOTE';
export const SET_NOTE_RESULT = 'SET_NOTE_RESULT';
const SET_NOTE_IS_LOADING = 'SET_NOTE_IS_LOADING';
const SET_NOTES_PAGINATION = 'SET_NOTES_PAGINATION';
const SET_IS_ADDING_COMMENT_TO_NOTE = 'SET_IS_ADDING_COMMENT_TO_NOTE';
export const ACTIVATE_NOTE = 'ACTIVATE_NOTE';
export const DEACTIVATE_NOTE = 'DEACTIVATE_NOTE';
export const INIT_NOTES = 'INIT_NOTES';
export const SET_IS_ADDING_TAG_TO_NOTE = 'SET_IS_ADDING_TAG_TO_NOTE';
export const SET_IS_EDITING_TAGS_ON_NOTE = 'SET_IS_EDITING_TAGS_ON_NOTE';

const routes = getRoutes(SECTION_NOTES);

export const activate = (idOrIds) =>
  Array.isArray(idOrIds)
    ? { type: ACTIVATE_NOTES, ids: idOrIds }
    : { type: ACTIVATE_NOTE, id: idOrIds };

export const beginAddingComment = (note_id) =>
  setIsAddingComment(note_id, true);

export const beginAdding = () =>
  setIsAdding(true);

export const beginLoading = (id) =>
  setIsLoading(id, true);

export const beginRequest = () => ({
  type: BEGIN_NOTES_REQUEST,
});

export const createNew = (kind_of, source_id) => dispatch => {
  dispatch(beginAdding());

  getNew({ id: source_id })
    .then(result => {
      const { data } = result;

      return buildNew(kind_of, data.note.record);
    })
    .then(note => {
      dispatch(beginLoading(note.id));
      dispatch(set(note, source_id, true, true));
      dispatch(edit(note.id));

      setTimeout(() => {
        dispatch(endLoading(note.id));
        dispatch(activate(note.id));
      }, 400);
    });
};

export const deactivate = (id) => ({
  type: DEACTIVATE_NOTE, id,
});

export const deleteItem = (id, source_id) => ({
  type: DELETE_NOTE, id, source_id,
});

export const edit = (id) => ({
  type: EDIT_NOTE, id,
});

export const endAdding = () =>
  setIsAdding(false);

export const endAddingComment = (note_id) =>
  setIsAddingComment(note_id, false);

export const endLoading = (id) =>
  setIsLoading(id, false);

export const endRequest = () => ({
  type: END_NOTES_REQUEST,
});

export const exitEdit = (id) => ({
  type: EXIT_EDIT_NOTE, id,
});

export const getNew = (source = null) =>
  get(source ? routes.new() + `?source_id=${source.id}` : routes.new());

export const init = () => ({
  type: INIT_NOTES,
});

export const processData = (note, source = null) => dispatch => {
  const isCitable = source?.medium?.is_citable ?? false;

  if (note.errors) {
    note.errors.map(error => {
      dispatch(setFlashWarning(error));
    });
  } else {
    dispatch(setResult(note)); // todo: nix after adapter is moved into action
    dispatch(set(adapt(note, isCitable)));
  }
};

export const remove = (id, source_id) => (dispatch, getState) => {
  const state = getState();
  const isNew = getIsNewId(state, id);
  const method = isNew ? removeNew : removeExisting;

  return dispatch(method(id, source_id));
};

const removeExisting = (id, source_id) => (dispatch, getState) => {
  const { env: { section, action } } = getState();
  const isNotesDetail = section.isNotes && action.isShow;

  deletePost(routes.detail(id))
    .then((result) => {
      if (isNotesDetail) {
        setTimeout(() => {
          redirectTo(routes.base());
        }, 400);
      } else {
        dispatch(deactivate(id));
        setTimeout(() => {
          dispatch(deleteItem(id, source_id));
          dispatch(setFlashSuccess('Your note was deleted'));
        }, 400);
      }

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));
};

const removeNew = (uuid, source_id) => dispatch => {
  dispatch(endAdding());

  dispatch(deactivate(uuid));
  setTimeout(() => dispatch(deleteItem(uuid, source_id)), 400);
};

export const save = (id, values) => (dispatch, getState) => {
  const state = getState();
  const isNew = getIsNewId(state, id);
  const { kind_of, source_id } = getValues(state, id);
  const data = {
    ...toFormValues(values),
    kind_of,
    source_id
  };
  const adapter = isNew ? toPost : toPut;
  const method = isNew ? saveNew : saveExisting;

  return dispatch(method(id, adapter(id, data)));
};

const saveExisting = (id, values) => dispatch =>
  put(routes.detail(id), values)
    .then(result => {
      const { data } = result;

      dispatch(exitEdit(data.note.record.id));
      dispatch(processData(data.note.record));

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

const saveNew = (uuid, adaptedData) => dispatch =>
  post(routes.new(), adaptedData)
    .then(result => {
      const { data } = result;

      dispatch(exitEdit(uuid));
      dispatch(deactivate(uuid));
      dispatch(deleteItem(uuid, data.note.record.source_id));
      dispatch(set(adapt(data.note.record), false, true));
      dispatch(activate(data.note.record.id));
      dispatch(endAdding());

      return result;
    })
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const set = (note, isNew = false, prepend = false) => ({
  type: SET_NOTE, note, isNew, prepend,
});

export const setResult = (note) => ({
  type: SET_NOTE_RESULT, note,
});

const setIsAdding = (isAddingNote) => ({
  type: SET_IS_ADDING_NOTE, isAddingNote,
});

const setIsAddingComment = (note_id, isAddingComment) => ({
  type: SET_IS_ADDING_COMMENT_TO_NOTE,
  id: note_id,
  isAddingComment
});

const setIsLoading = (id, isLoading) => ({
  type: SET_NOTE_IS_LOADING, id, isLoading,
});

export const setPagination = (pagination) => ({
  type: SET_NOTES_PAGINATION, pagination,
});

const additionalIdFields = [
  'activeIds',
  'editingIds',
  'existingIds',
  'newIds',
  'isAddingCommentToIds',
  'isAddingTagToIds',
  'isEditingTagsOnIds',
];

const initialState = getInitialState({
  isAdding: false,
  isRequesting: false,
  pagination: null,
}, additionalIdFields);

export default create({
  [INIT_NOTES]: () => initialState,

  [BEGIN_NOTES_REQUEST]: state => setIsRequesting(state, true),
  [END_NOTES_REQUEST]: state => setIsRequesting(state, false),

  [SET_IS_ADDING_NOTE]: (state, { isAddingNote }) => ({
    ...state,
    isAdding: isAddingNote,
  }),

  [SET_NOTE]: (state, { note, isNew, prepend }) => ({
    ...state,
    entities: mergeEntityValues(state, note),
    ids: prepend
      ? uniq([ note.id, ...state.ids ])
      : uniq([ ...state.ids, note.id ]),
    existingIds: isNew
      ? state.existingIds
      : prepend
        ? uniq([ note.id, ...state.existingIds ])
        : uniq([ ...state.existingIds, note.id ]),
    newIds: isNew
      ? uniq([ ...state.newIds, note.id ])
      : state.newIds,
  }),

  [EDIT_NOTE]: (state, { id }) => toggleId(state, id, 'editingIds', true),
  [EXIT_EDIT_NOTE]: (state, { id }) => toggleId(state, id, 'editingIds', false),

  [SET_NOTE_IS_LOADING]: (state, { id, isLoading }) => ({
    ...state,
    entities: {
      ...state.entities,
      [id]: {
        ...state.entities[id],
        isLoading,
      }
    }
  }),

  [SET_NOTES_PAGINATION]: (state, { pagination }) => ({
    ...state,
    pagination,
  }),

  [SET_IS_ADDING_COMMENT_TO_NOTE]: (state, { id, isAddingComment }) => toggleId(state, id, 'isAddingCommentToIds', isAddingComment),
  [SET_IS_ADDING_TAG_TO_NOTE]: (state, { id, isAddingTag }) => toggleId(state, id, 'isAddingTagToIds', isAddingTag),
  [SET_IS_EDITING_TAGS_ON_NOTE]: (state, { id, isEditingTags }) => toggleId(state, id, 'isEditingTagsOnIds', isEditingTags),
  [ACTIVATE_NOTE]: (state, { id }) => toggleId(state, id, 'activeIds', true),
  [ACTIVATE_NOTES]: (state, { ids }) => toggleId(state, ids, 'activeIds', true),
  [DEACTIVATE_NOTE]: (state, { id }) => toggleId(state, id, 'activeIds', false),
  [DELETE_NOTE]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
}, initialState);
