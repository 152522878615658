import { SECTION_PERSON_TAGS } from '../constants.js';

import uniq from '../lib/array/uniq';
import withoutProp from '../lib/object/without';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeAllEntities,
  mergeEntityValues,
  setUniqueIds,
  toggleId,
} from '../lib/redux-tools';
import { deletePost, post } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes } from '../lib/route';

import { setWarning } from '../actions/messages';

import buildNewPersonTag from '../adapters/person-tag/build-new';
import toPost from '../adapters/person-tag/to-post';
import { buildNewTag } from '../adapters/tags';

import {
  SET_IS_ADDING_TAG_TO_PERSON,
  SET_IS_EDITING_TAGS_ON_PERSON,
} from './people';
import {
  deleteItem as deleteTag,
  saveNewWithValue as saveNewTagWithValue,
  set as setTag,
} from './tags';

import {
  getIsNewId,
  getTagIsSetOnPerson,
  getValues,
} from '../selectors/person-tags';
import { getMatchingTag } from '../selectors/tags';

export const ACTIVATE_PERSON_TAG = 'ACTIVATE_PERSON_TAG';
const ACTIVATE_PERSON_TAGS = 'ACTIVATE_PERSON_TAGS';
export const DEACTIVATE_PERSON_TAG = 'DEACTIVATE_PERSON_TAG';
export const DELETE_PERSON_TAG = 'DELETE_PERSON_TAG';
export const SET_PERSON_TAG = 'SET_PERSON_TAG';
export const SET_PERSON_TAGS = 'SET_PERSON_TAGS';

const routes = getRoutes(SECTION_PERSON_TAGS);

export const activate = (idOrIds) =>
  Array.isArray(idOrIds)
    ? { type: ACTIVATE_PERSON_TAGS, ids: idOrIds }
    : { type: ACTIVATE_PERSON_TAG, id: idOrIds };

export const beginAddingToPerson = (personId) =>
  setIsAddingToPerson(personId, true);

export const beginEditingOnPerson = (personId, ids) =>
  setIsEditingOnPerson(personId, true, ids);

export const createNew = personId => dispatch => {
  const tag = buildNewTag();
  const personTag = buildNewPersonTag(personId, tag.id);

  Promise.resolve(
    dispatch(setTag(tag, true)),
    dispatch(set(personTag, true))
  ).then(() => {
    dispatch(beginAddingToPerson(personId));
  }).then(() => {
    dispatch(activate(personTag.id));
  });
};

export const deactivate = (id) => ({
  type: DEACTIVATE_PERSON_TAG, id,
});

export const deleteItem = (id) => ({
  type: DELETE_PERSON_TAG, id,
});

export const endAddingToPerson = (personId) =>
  setIsAddingToPerson(personId, false);

export const endEditingOnPerson = (personId, ids) =>
  setIsEditingOnPerson(personId, false, ids);

export const warnIsDuplicate = (value) =>
  setWarning(`You’ve already tagged this person with “${value}”`);

export const removeUnsaved = (itemId, id, tagId) => dispatch => {
  Promise.resolve(
    dispatch(deactivate(id))
  ).then(() => {
    setTimeout(() => {
      dispatch(endAddingToPerson(itemId));
      dispatch(deleteItem(id));
      dispatch(deleteTag(tagId));
    }, 400);
  });
};

export const remove = id => dispatch => {
  // todo: nope
  Promise.resolve(
    dispatch(deactivate(id))
  ).then(() => {
    deletePost(routes.detail(id))
      .then(() => {
        dispatch(deleteItem(id));
      })
      .catch(() => {
        dispatch(setWarning());
      });
  });
};

export const saveNew = (_id, values) => dispatch =>
  post(routes.new(), toPost(_id, values))
    .catch(noop)
    .then(result => dispatch(dispatchActions(result)));

export const saveNewWithId = (_id, tagId, values) => dispatch => {
  let newValues = values;

  newValues = withoutProp(newValues, 'id');
  newValues = withoutProp(newValues, 'title');
  newValues = { ...newValues, tag_id: tagId };

  return dispatch(saveNew(_id, newValues))
    .then(result => {
      const { data } = result;
      const { person_tag } = data;

      dispatch(set(person_tag.record));
      dispatch(activate(person_tag.record.id));

      return result;
    });
};

const saveNewWithTitle = (title, values) => dispatch =>
  dispatch(saveNewTagWithValue(title))
    .then(result => {
      const { data } = result;
      const { tag } = data;

      dispatch(setTag(tag.record));

      dispatch(saveNew(values.id, {
        ...values,
        tag_id: tag.record.id,
      }))
        .then(result => {
          const { data } = result;
          const { person_tag } = data;

          dispatch(set(person_tag.record));
          dispatch(activate(person_tag.record.id));
        });

      return result;
    });

export const save = (_id, formValues) => (dispatch, getState) => {
  const state = getState();

  const isNewPersonTag = getIsNewId(state, _id);

  if (isNewPersonTag) {
    const values = getValues(state, _id);
    const { title } = formValues;
    const matchingTag = getMatchingTag(state, title);

    if (matchingTag) {
      const { person_id } = values;
      const tagIsSetOnPerson = getTagIsSetOnPerson(state, person_id, matchingTag.id);

      if (tagIsSetOnPerson) {
        return dispatch(warnIsDuplicate(title));
      } else {
        return dispatch(saveNewWithId(_id, matchingTag.id, values));
      }
    } else {
      return dispatch(saveNewWithTitle(title, values));
    }
  } else {
    return dispatch({ type: "NOOP" });
  }
};

export const set = (personTag, isNew = false) => ({
  type: SET_PERSON_TAG, personTag, isNew,
});

export const setAll = (personTags) => ({
  type: SET_PERSON_TAGS, personTags,
});

export const setIsAddingToPerson = (personId, isAddingTag) => ({
  type: SET_IS_ADDING_TAG_TO_PERSON, id: personId, isAddingTag,
});

export const setIsEditingOnPerson = (personId, isEditingTags, ids) => ({
  type: SET_IS_EDITING_TAGS_ON_PERSON, id: personId, isEditingTags, ids,
});

const additionalIdFields = [
  'activeIds',
  'editingIds',
  'newIds',
];

const initialState = getInitialState({}, additionalIdFields);

export default create({
  [SET_PERSON_TAGS]: (state, { personTags }) => ({
    ...state,
    entities: mergeAllEntities(state, personTags),
    ids: setUniqueIds(state, personTags),
  }),

  [SET_PERSON_TAG]: (state, { personTag, isNew }) => ({
    ...state,
    entities: mergeEntityValues(state, personTag),
    ids: isNew
      ? state.ids
      : uniq([ ...state.ids, personTag.id ]),
    newIds: isNew
      ? uniq([ ...state.newIds, personTag.id ])
      : state.newIds,
  }),

  [SET_IS_EDITING_TAGS_ON_PERSON]: (state, { ids, isEditingTags }) => toggleId(state, ids, 'editingIds', isEditingTags),
  [ACTIVATE_PERSON_TAG]: (state, { id }) => toggleId(state, id, 'activeIds', true),
  [ACTIVATE_PERSON_TAGS]: (state, { ids }) => toggleId(state, ids, 'activeIds', true),
  [DEACTIVATE_PERSON_TAG]: (state, { id }) => toggleId(state, id, ['activeIds', 'editingIds'], false),
  [DELETE_PERSON_TAG]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
}, initialState);
