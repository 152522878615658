import {
  create,
  setIsRequesting,
} from '../lib/redux-tools';
import { get } from '../lib/request';

import { INIT_ALL } from './env';

const BEGIN_CITATION_REQUEST = 'BEGIN_CITATION_REQUEST';
const END_CITATION_REQUEST = 'END_CITATION_REQUEST';
export const SET_CITATION = 'SET_CITATION';
const SET_DOCSTYLES = 'SET_DOCSTYLES';

export const beginRequest = () => ({
  type: BEGIN_CITATION_REQUEST
});

export const endRequest = () => ({
  type: END_CITATION_REQUEST
});

export const getCitation = path => dispatch => {
  dispatch(beginRequest());

  return get(`${path}/citation`)
    .then(result => {
      const { data } = result;
      const { citation } = data;

      dispatch(set(citation.record));
      dispatch(endRequest());
    });
};

export const set = (citation) => ({
  type: SET_CITATION, citation,
});

export const setDocstyles = (docstyles) => ({
  type: SET_DOCSTYLES, docstyles,
});

const initialState = {
  docstyles: {},
  isRequesting: false,
  entities: {},
};

export default create({
  [INIT_ALL]: () => initialState,

  [BEGIN_CITATION_REQUEST]: state => setIsRequesting(state, true),
  [END_CITATION_REQUEST]: state => setIsRequesting(state, false),

  [SET_DOCSTYLES]: (state, { docstyles }) => {
    return {
      ...state,
      docstyles: {
        ...docstyles,
      },
    }
  },

  [SET_CITATION]: (state, { citation }) => {
    const { collection, id } = citation;

    return {
      ...state,
      entities: {
        ...state.entities,
        [id]: collection,
      },
    }
  },
}, initialState);
