import {
  OPEN_LIBRARY_SEARCH,
  OPEN_LIBRARY_BOOKS_ISBN,
  OPEN_LIBRARY_USE_BOOKS_ISBN,
} from '../../../endpoints.js';
import { getRemoteStatus, isOk } from '../../../lib/request';

export default function() {
  return getRemoteStatus(
      OPEN_LIBRARY_USE_BOOKS_ISBN
        ? OPEN_LIBRARY_BOOKS_ISBN
        : OPEN_LIBRARY_SEARCH
    )
    .then(response => isOk(response))
    .catch(() => false);
}
