import { activateForm, activateResults } from '../reducers/lookup';
import { getPreviousStep } from '../selectors/lookup';

export default function(store) {
  const { dispatch, getState } = store;

  const previousStep = getPreviousStep(getState());

  if (previousStep?.form) {
    dispatch(activateForm(previousStep.form));
  } else if (previousStep?.results) {
    dispatch(activateResults());
  }
}
