import {
  activate,
} from '../reducers/tasks';

export default function(store, action) {
  const { dispatch } = store;
  const { isNew, task } = action;

  if (isNew) {
    setTimeout(() => {
      dispatch(activate(task.id));
    }, 250);
  } else {
    dispatch(activate(task.id));
  }
}
