import { SECTION_TASKS } from '../constants.js';

import uniq from '../lib/array/uniq';
import {
  create,
  deleteEntityAtId,
  getInitialState,
  mergeAllEntities,
  mergeEntityValues,
  setUniqueIds,
  toggleId,
  updateItem,
} from '../lib/redux-tools';
import { deletePost, post, put } from '../lib/request';
import { dispatchActions, noop } from '../lib/response';
import { getRoutes } from '../lib/route';

import { setWarning } from '../actions/messages';

import adapt from '../adapters/task/adapt';
import toPost from '../adapters/task/to-post';
import toPut from '../adapters/task/to-put';

const ACTIVATE_TASK = 'ACTIVATE_TASK';
const ACTIVATE_TASKS = 'ACTIVATE_TASKS';
const SET_IS_ADDING_TASK = 'SET_IS_ADDING_TASK';
const SET_IS_EDITING_TASKS = 'SET_IS_EDITING_TASKS';
const DEACTIVATE_TASK = 'DEACTIVATE_TASK';
const DELETE_TASK = 'DELETE_TASK';
export const SET_TASK = 'SET_TASK';
const SET_TASKS = 'SET_TASKS';
const UPDATE_TASK = 'UPDATE_TASK';

const routes = getRoutes(SECTION_TASKS);

export const activate = (idOrIds) =>
  Array.isArray(idOrIds)
    ? { type: ACTIVATE_TASKS, ids: idOrIds }
    : { type: ACTIVATE_TASK, id: idOrIds };

export const beginAdding = (id) =>
  setIsAdding(id, true);

export const beginEditing = (id) =>
  setIsEditing(id, true);

export const cancelAdd = (id) => dispatch => {
  dispatch(deactivate(id));
  dispatch(endAdding(id));

  setTimeout(() => {
    dispatch(deleteItem(id));
  }, 400);
};

export const cancelEdit = (id) => dispatch => {
  dispatch(endEditing(id));
};

export const deactivate = (id) => ({
  type: DEACTIVATE_TASK, id,
});

export const deleteItem = (id) => ({
  type: DELETE_TASK, id,
});

export const endAdding = (id) =>
  setIsAdding(id, false);

export const endEditing = (id) =>
  setIsEditing(id, false);

export const remove = id => async dispatch => {
  const result = await deletePost(routes.remove(id)).catch(() => {
    dispatch(setWarning());
  });

  if (result) {
    dispatch(deactivate(id));

    setTimeout(async () => {
      dispatch(deleteItem(id));
    }, 400);
  }

  return result;
};

export const saveExisting = (id, values) => async dispatch => {
  const result = await put(routes.detail(id), toPut(id, values)).catch(noop);
  const { data: { task } } = result;

  dispatch(update(adapt(task.record)));
  dispatch(dispatchActions(result));

  return result;
};

export const saveNew = (_id, values) => async dispatch => {
  const result = await post(routes.new(), toPost(_id, values)).catch(noop);
  const { data: { task } } = result;

  dispatch(set(adapt(task.record)));
  dispatch(dispatchActions(result));

  return result;
};

export const set = (task, isNew = false) => ({
  type: SET_TASK, task, isNew,
});

export const setAll = (tasks) => ({
  type: SET_TASKS, tasks,
});

export const setIsAdding = (id, isAdding) => ({
  type: SET_IS_ADDING_TASK, id, isAdding,
});

export const setIsEditing = (id, isEditing, ids) => ({
  type: SET_IS_EDITING_TASKS, id, isEditing, ids,
});

export const update = (values) => ({
  type: UPDATE_TASK, values,
});

const additionalIdFields = [
  'activeIds',
  'addingIds',
  'editingIds',
  'newIds',
];

const initialState = getInitialState({
  isRequesting: false,
  isAdding: false,
  pagination: null,
}, additionalIdFields);

export default create({
  [SET_TASKS]: (state, { tasks }) => ({
    ...state,
    entities: mergeAllEntities(state, tasks),
    ids: setUniqueIds(state, tasks),
  }),

  [SET_TASK]: (state, { task, isNew }) => ({
    ...state,
    entities: mergeEntityValues(state, task),
    ids: uniq([ ...state.ids, task.id ]),
    editingIds: isNew
      ? uniq([ ...state.editingIds, task.id ])
      : state.editingIds,
    newIds: isNew
      ? uniq([ ...state.newIds, task.id ])
      : state.newIds,
  }),

  [UPDATE_TASK]: (state, { values }) => updateItem(state, values),

  [SET_IS_ADDING_TASK]: (state, { id, isAdding }) => toggleId(state, id, 'addingIds', isAdding),
  [SET_IS_EDITING_TASKS]: (state, { ids, isEditing }) => toggleId(state, ids, 'editingIds', isEditing),

  [ACTIVATE_TASK]: (state, { id }) => toggleId(state, id, 'activeIds', true),
  [ACTIVATE_TASKS]: (state, { ids }) => toggleId(state, ids, 'activeIds', true),

  [DEACTIVATE_TASK]: (state, { id }) => toggleId(state, id, ['activeIds', 'editingIds'], false),
  [DELETE_TASK]: (state, { id }) => deleteEntityAtId(state, id, additionalIdFields),
}, initialState);
