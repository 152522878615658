import keyById from './array/key-by-id';
import uniq from './array/uniq';
import without from './array/without';
import withoutProp from './object/without';

export const create = (reducers, initialState) => (state = initialState, action) => {
  const reducer = reducers[action.type];
  return reducer ? reducer(state, action) : state;
};

export const getInitialState = (additionalFields, additionalIdFields) => {
  const initialState = {
    entities: {},
    ids: [],
    ...additionalFields,
  };

  additionalIdFields?.forEach(idField => {
    initialState[idField] = [];
  });

  return initialState;
};

export const setIsAdding = (state, isAdding = false) => ({
  ...state,
  isAdding: isAdding,
});

export const setIsRequesting = (state, isRequesting = false) => ({
  ...state,
  isRequesting: isRequesting,
});

const toggleIdOnField = (state, idOrIds, included) => {
  const isArrayOfIds = Array.isArray(idOrIds);

  return included === true
    ? isArrayOfIds
      ? uniq([ ...state, ...idOrIds ])
      : uniq([ ...state, idOrIds ])
    : without(state, idOrIds);
};

const toggleIdOnFields = (state, idOrIds, fieldNames, included) => {
  const fields = {};

  if (Array.isArray(fieldNames)) {
    fieldNames
      .filter(fieldName => fieldName in state)
      .forEach(fieldName => {
        fields[fieldName] = toggleIdOnField(state[fieldName], idOrIds, included);
      });
  } else {
    const fieldName = fieldNames;

    fields[fieldName] = toggleIdOnField(state[fieldName], idOrIds, included);
  }

  return fields;
};

export const toggleId = (state, idOrIds, fieldNames, included) => ({
  ...state,
  ...toggleIdOnFields(state, idOrIds, fieldNames, included),
});

export const normalizeEntities = (objects, toInt = true) => {
  const entities = Array.isArray(objects) ? keyById(objects) : objects;
  const ids = Object.keys(entities);

  return {
    entities: entities,
    ids: toInt ? ids.map(Number) : ids
  };
};

export const mergeEntityValues = (state, values) => {
  const id = values.id;

  return {
    ...state.entities,
    [id]: {
      ...state.entities[id],
      ...values,
    }
  };
};

export const updateItem = (state, values) => ({
  ...state,
  entities: mergeEntityValues(state, values),
});

export const mergeAllEntities = (state, items) => {
  let objects;

  if (Array.isArray(items)) {
    objects = keyById(items);
  } else if ('entities' in items) {
    objects = items.entities;
  } else {
    objects = items;
  }

  return {
    ...state.entities,
    ...Object.keys(objects).reduce((entities, id) => {
      const values = objects[id];

      entities[id] = {
        ...state.entities[id],
        ...values,
      };

      return entities;
    }, {}),
  };
};

export const setUniqueIds = (state, items, prop = 'ids') => {
  if (Array.isArray(items)) {
    return uniq([ ...state[prop], ...items.map(item => item.id) ]);
  } else {
    return uniq([ ...state[prop], ...items[prop] ]);
  }
};

export const deleteEntityAtId = (state, id, additionalIdFields = []) => {
  const remainder = withoutProp(state.entities, id);
  const entities = {
    ...remainder
  };
  const ids = without(state.ids, id);
  const optionalFields = {};

  additionalIdFields
    .filter(additionalIdArray => additionalIdArray in state)
    .forEach(additionalIdArray => {
      optionalFields[additionalIdArray] = without(state[additionalIdArray], id);
    });

  return {
    ...state,
    entities,
    ids,
    ...optionalFields,
  };
};
