import {
  INTERNAL_LOOKUP_BOOKS,
  INTERNAL_LOOKUP_JOURNALS,
  INTERNAL_LOOKUP_MAGAZINES,
  INTERNAL_LOOKUP_NEWSPAPERS,
  INTERNAL_LOOKUP_PERIODICALS,
  OPEN_LIBRARY_SEARCH,
  OPEN_LIBRARY_BOOKS_ISBN
} from './endpoints.js';

const ACTION_DELETE = 'delete';
export const ACTION_EDIT = 'edit';
const ACTION_INDEX = 'index';
export const ACTION_NEW = 'new';
const ACTION_SHOW = 'show';

export const ACTIONS = [
  ACTION_DELETE,
  ACTION_EDIT,
  ACTION_INDEX,
  ACTION_NEW,
  ACTION_SHOW
];

export const SECTION_COMMENTS = 'comments';
export const SECTION_INDEXES = 'indexes';
export const SECTION_LOOKUP = 'lookup';
export const SECTION_NOTE_COMMENT_TASKS = 'note_comment_tasks';
export const SECTION_NOTE_SOURCES = 'note_sources';
export const SECTION_NOTE_TAGS = 'note_tags';
export const SECTION_NOTES = 'notes';
export const SECTION_PEOPLE = 'people';
export const SECTION_PERSON_TAGS = 'person_tags';
export const SECTION_SETTINGS = 'settings';
export const SECTION_SOURCE_TAGS = 'source_tags';
export const SECTION_SOURCES = 'sources';
export const SECTION_TAGS = 'tags';
export const SECTION_TASKS = 'tasks';
export const SECTION_USERS = 'users';

export const MEDIUMS = {
  book: {
    model: 'Book',
    collection: 'Books',
    endpoints: {
      local: INTERNAL_LOOKUP_BOOKS,
      openlibrary: {
        search: OPEN_LIBRARY_SEARCH,
        books: OPEN_LIBRARY_BOOKS_ISBN,
      }
    },
    search: {
      label: 'ISBN',
      key: 'query',
      maxLength: 17,
      pattern: '^(97(8|9))?[0-9]{9}[0-9x]$',
      placeholder: 'ISBN',
      preflight: /[-\s]/g,
    }
  },
  periodical: {
    model: 'Periodical',
    collection: 'Periodicals',
    endpoints: {
      local: INTERNAL_LOOKUP_PERIODICALS,
    },
    search: {
      label: 'title',
      key: 'query',
      maxLength: 36,
      pattern: '([\\W\\w]{3,})',
      placeholder: 'Title'
    }
  },
  journal: {
    model: 'Journal',
    collection: 'Journals',
    endpoints: {
      local: INTERNAL_LOOKUP_JOURNALS,
    },
    search: {
      label: 'title',
      key: 'title',
      maxLength: 36,
      pattern: '([\\W\\w]{3,})',
      placeholder: 'Title',
    }
  },
  magazine: {
    model: 'Magazine',
    collection: 'Magazines',
    endpoints: {
      local: INTERNAL_LOOKUP_MAGAZINES,
    },
    search: {
      label: 'title',
      key: 'title',
      maxLength: 36,
      pattern: '([\\W\\w]{3,})',
      placeholder: 'Title',
    }
  },
  newspaper: {
    model: 'Newspaper',
    collection: 'Newspapers',
    endpoints: {
      local: INTERNAL_LOOKUP_NEWSPAPERS,
    },
    search: {
      label: 'title',
      key: 'title',
      maxLength: 36,
      pattern: '([\\W\\w]{3,})',
      placeholder: 'Title',
    }
  },
  personal_communication: {
    model: 'PersonalCommunication',
    collection: 'PersonalCommunications',
  },
  summa: {
    model: 'Summa',
    collection: 'Works',
  },
  divine_comedy: {
    model: 'DivineComedy',
    collection: 'Works',
  },
  weblog: {
    model: 'Weblog',
    collection: 'Weblogs',
  },
  website: {
    model: 'Website',
    collection: 'Websites',
  }
};

export const WORKS = ['summa', 'divine_comedy'];
export const PERIODICALS = ['journal', 'magazine', 'newspaper', 'weblog', 'website'];
export const SELECTIONS = ['introduction', 'foreword', 'preface', 'article', 'essay', 'story', 'poem', 'interview', 'speech', 'post', 'appendix', 'selection'];
// export const PERSONAL_COMMUNICATIONS = ['email'];

export const PAGE_BREAK_CHARACTER = '↡';

export const PARTITIONINGS = {
  'book-chapter-verse': {
    mask: /^[a-z]+\.?\s[\d]{1,2}(?:[.:](.*))$/i // just until we get back-end validation
  },
};

export const PATTERNS = {
  pathname: /^\/([\w-]+)(?:\/(?!\bnew\b)([a-z-]+))?(?:\/([0-9]+|new))?(?:\/([\w-]+))?\/?$/,
};

export const FREQUENCIES = [
  { value: 'daily', label: 'daily' },
  { value: 'weekly', label: 'weekly' },
  { value: 'monthly', label: 'monthly' },
  { value: 'biweekly', label: 'biweekly' },
  { value: 'semimonthly', label: 'twice a month (semimonthly)' },
  { value: 'triweekly', label: 'triweekly' },
  { value: 'quarterly', label: 'quarterly' },
  { value: 'biannually', label: 'twice a year (biannually)' },
  { value: 'triannually', label: 'three times a year' },
  { value: 'four times a year', label: 'four times a year (quarterly)' },
  { value: 'five times a year', label: 'five times a year' },
  { value: 'bimonthly', label: 'six times a year (bimonthly)' },
  { value: 'seven times a year', label: 'seven times a year' },
  { value: 'eight times a year', label: 'eight times a year' },
  { value: 'nine times a year', label: 'nine times a year' },
  { value: 'ten times a year', label: 'ten times a year' },
  { value: 'eleven times a year', label: 'eleven times a year' },
  { value: 'irregular', label: 'irregular/none' }
];

// todo: eh
export const LOOKUP = 'lookup';
export const NEW_SELECTION = 'new-selection';

export const FORM_NAME_STEM = 'source';

export const LOOKUP_RESULTS_LIMIT = 10;
