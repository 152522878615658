import { createSelector } from 'reselect'

import toFormValues from '../adapters/source-tag/to-form-values';
import getStringsForNullValues from '../lib/object/get-strings-for-null-values';

import { getById as getSourcesById } from './sources';

const getSourceTagsState = state => state.sourceTags;

const getById = createSelector(getSourceTagsState, sourceTags => sourceTags.entities);
const getIds = createSelector(getSourceTagsState, sourceTags => sourceTags.ids);
export const getActiveIds = createSelector(getSourceTagsState, sourceTags => sourceTags.activeIds);
export const getEditingIds = createSelector(getSourceTagsState, sourceTags => sourceTags.editingIds);
const getNewIds = createSelector(getSourceTagsState, sourceTags => sourceTags.newIds);

const getSourceTags = createSelector(
  [getIds, getById], (ids, entities) => ids.map(id => entities[id])
);

const getNewSourceTags = createSelector(
  [getNewIds, getById], (ids, entities) => ids.map(id => entities[id])
);

export const getSourceTagsForSourceId = (state, sourceId) =>
  getSourceTags(state).filter(sourceTag => sourceTag.source_id === sourceId);

export const getNewSourceTagForSourceId = (state, sourceId) =>
  getNewSourceTags(state).find(sourceTag => sourceTag.source_id === sourceId);

export const getTagIsSetOnSource = (state, sourceId, tagId) =>
  getSourceTagsForSourceId(state, sourceId).map(sourceTag => sourceTag.tag_id).includes(tagId);

export const getIsNewId = (state, id) => getNewIds(state).includes(id);

export const getSourceTagAtId = (state, id) => getById(state)[id] || {};
export const getInitialState = (state, id) => toFormValues(getStringsForNullValues(getSourceTagAtId(state, id)));
export const getValues = (state, id) => toFormValues(getSourceTagAtId(state, id));

export const getSourcesByTagId = createSelector(
  [getSourceTags, getSourcesById], (sourceTags, sourcesById) => {
    return sourceTags.reduce((sourcesByTagId, sourceTag) => {
      const { source_id, tag_id } = sourceTag;

      if (!(tag_id in sourcesByTagId)) {
        sourcesByTagId[tag_id] = [];
      }

      sourcesByTagId[tag_id].push(sourcesById[source_id]);

      return sourcesByTagId;
    }, {});
  }
);
