import {
  addToRemoveQueue,
  removeFromActivateQueue,
} from '../reducers/messages';

export default function(store, action) {
  const { dispatch, getState } = store;

  if (getState().messages.idsToActivate.includes(action.id)) {
    dispatch(removeFromActivateQueue(action.id));
    dispatch(addToRemoveQueue(action.id));
  }
}
