import { createSelector } from 'reselect'

import { getHasFeature } from './env';

const getState = state => state;
const getLookupState = state => state.lookup;

const getLookupQuestion = createSelector(getLookupState, lookup => lookup.question);
const getLookupPrompt = createSelector(getLookupState, lookup => lookup.prompt);
const getLookupResults = createSelector(getLookupState, lookup => lookup.results);
export const getSourceId = createSelector(getLookupState, lookup => lookup.sourceId);
const getSteps = createSelector(getLookupState, lookup => lookup.steps);
const getStepResults = createSelector(getLookupState, lookup => lookup.stepResults);

export const getStep = createSelector(getSteps, steps => steps.at(-1));
export const getPreviousStep = createSelector(getSteps, steps => steps.at(-2));
export const getHasPreviousStep = createSelector(getPreviousStep, step => Boolean(step));
export const getNextStep = createSelector(getStep, step => step?.next ?? null);
export const getCanSkipStep = createSelector([getStep, getNextStep], (step, next) => Boolean(step?.skippable) && Boolean(next));
export const getStepIsSkippable = createSelector(getStep, step => Boolean(step?.skippable));
export const getSkippableNextStep = createSelector(
  [getStepIsSkippable, getStep],
  (isSkippable, step) => isSkippable ? step?.skippable?.next : null
);
export const getHasNextStep = createSelector(getSkippableNextStep, next => next !== null);

const getHasNavigation = createSelector(getState, state => getHasFeature(state, 'lookup_navigation'));
export const getCanNavigate = createSelector(
  [getHasNavigation, getStep],
  (hasNavigation, step) => hasNavigation && Boolean(step)
);
export const getIsNavigationActive = createSelector(
  [getCanNavigate, getStep],
  (canNavigate, step) => canNavigate && !['start', 'final-review-tbd'].includes(step.step)
);

export const getMode = createSelector(getStep, step => step?.mode ?? null);

const getIsQuestion = createSelector(getMode, mode => mode === 'question');
export const getIsDirective = createSelector(getMode, mode => mode === 'directive');
const getIsResults = createSelector(getMode, mode => mode === 'results');

export const getQuestionChoices = createSelector(getStep, step => step?.choices ?? []);
const getHasQuestionChoices = createSelector(getQuestionChoices, choices => choices.length > 0);
export const getHasQuestion = createSelector(
  [getIsQuestion, getHasQuestionChoices],
  (isQuestion, hasChoices) => isQuestion && hasChoices
);
export const getQuestionIsActive = createSelector(
  [getIsQuestion, getLookupQuestion],
  (isQuestion, question) => isQuestion && question.isActive
);
export const getShowQuestionChoices = createSelector(getLookupQuestion, question => question.showChoices);

export const getPrompt = createSelector(
  [getLookupPrompt, getStep],
  (prompt, step) => prompt.message === null ? step?.prompt : prompt
);
export const getHasPromptMessage = createSelector(
  getPrompt, (prompt) => Boolean(prompt?.message)
);

export const getResults = createSelector(getStep, step => step?.results ?? []);
export const getHasResults = createSelector([getIsResults, getResults], (isResults, results) => isResults && results.length > 0);
export const getIsResultsActive = createSelector(getLookupResults, results => results.isActive);
export const getShowResults = createSelector(getLookupResults, results => results.showResults);

const getStepIds = createSelector(getSteps, steps => steps.map(step => step.step));
const getStepChoiceValues = createSelector(
  [getSteps, getStepIds, getStepResults],
  (steps, ids, stepResults) => {
    return ids.reverse().map(id => {
      const stepForId = steps.find(step => step.step === id);
      const stepForIdIndex = steps.indexOf(stepForId);
      const previousStep = steps.at(stepForIdIndex - 1);

      if (previousStep.results && stepResults.length) {
        const stepResult = stepResults.find(r => r.step === previousStep.step);
        const result = previousStep.results.find(r => r.id === stepResult.resultId);

        return result.record.medium;
      } else if (previousStep.choices) {
        const choice = previousStep.choices.find(c => c.next === id);

        if (choice?.values) {
          return choice.values;
        }
      }

      return null;
    })
      .filter(Boolean)
      .reverse();
  }
);
export const getStepValues = createSelector(
  [getSteps, getStepIds, getStepChoiceValues],
  (steps, ids, choiceValues) => {
    return choiceValues.reduce((sum, atStep) => {
      return {
        ...sum,
        ...atStep
      };
    }, {});
  }
);

export const getFormIsActive = createSelector(getLookupState, lookup => lookup.form.isActive);
export const getForm = createSelector(getStep, step => step?.form);
const getFormType = createSelector(getForm, form => form?.type);
export const getIsShowingResult = createSelector(getFormType, type => type === 'result');
export const getIsShowingSearch = createSelector(getFormType, type => type === 'search');
export const getHasForm = createSelector(
  [getIsDirective, getFormType],
  (isDirective, type) => isDirective && Boolean(type)
);
