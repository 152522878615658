import React, { ReactNode } from 'react';

interface Props {
  actionRequired: boolean;
  children: ReactNode;
  isDisabled?: boolean;
  title: string;
  type: string;
}

const Action = ({
  actionRequired = false,
  children,
  isDisabled = false,
  title,
  type,
}: Props) => {
  const disabled = isDisabled && { disabled: true, 'data-disabled': true };

  return (
    <div className={`item-action item-active item-action-${type}`}>
      <button
        className='item-action-el item-action-button item-action-has-graphic'
        type='submit'
        tabIndex={0}
        data-action-required={actionRequired}
        title={title}
        {...disabled}
      >
        {children}
      </button>
    </div>
  );
};

export default Action;
