import {
  cancelAdd,
  cancelEdit,
} from '../reducers/tasks';
import {
  getAtId,
  getIsNewId,
} from '../selectors/note-comment-tasks';

// todo: rebuild around set is adding to note comment

export default function(store, action) {
  const { dispatch, getState } = store;
  const { id } = action;

  const state = getState();

  const noteCommentTask = getAtId(state, id);
  const isNew = getIsNewId(state, id);

  if (isNew) {
    dispatch(cancelAdd(noteCommentTask.task_id));
  } else {
    dispatch(cancelEdit(noteCommentTask.task_id));
  }
}
